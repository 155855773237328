import { Component, Input } from "@angular/core";
import { TranslationHelper } from "src/app/survey/shared/helpers/translation.helper";
import { QuestionType } from "src/app/survey/shared/models/question-type.enum";
import { Smileys } from "src/app/survey/shared/models/smiley.model";
import { SurveyQuestion } from "src/app/survey/shared/models/survey-question.model";
import { SurveyResponseModel } from "src/app/survey/shared/models/survey-response.model";

@Component({
    selector: "app-survey-question",
    templateUrl: "./question.component.html",
    styleUrls: ["./question.component.scss"],
})
export class QuestionComponent  {

    @Input()
    public question: SurveyQuestion;

    @Input()
    public response: SurveyResponseModel;

    @Input()
    public locale: string;

    // expose the QuestionType enum to the template
    public QuestionTypeEnum = QuestionType

    // get the enum keys for the Smileys
    public get smileys(): string[] {
        const keys = Object.keys(Smileys).filter(key => isNaN(Number(key)));
        return keys;
    }

    public getValueFromSmiley(key: string): string {
        return Smileys[key];
    }

    public get Title(): string {
        return TranslationHelper.getTranslation(this.question.title, this.locale);
    }

    public get Description(): string {
        return TranslationHelper.getTranslation(this.question.description, this.locale);
    }
}
