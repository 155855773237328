<div class="hic-variables-mini-display">
    <mat-accordion [multi]="true">
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>Variables</mat-expansion-panel-header>
            <div class="add-variable">  
                <mat-form-field appearance="fill" subscriptSizing="dynamic" class="searchField">
                    <mat-icon matIconPrefix>search</mat-icon>
                    <mat-label>Search variables</mat-label>
                    <input #searchInput matInput type="text" [(ngModel)]="searchFieldInput" 
                    (ngModelChange)="searchInputChange()" [matAutocomplete]="auto" [formControl]="filterOptionControl">
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionselected($event)">
                        <mat-option *ngFor="let item of filteredList" [value]="item">
                            {{item.title}}
                        </mat-option>
                        <mat-option *ngIf="filteredList.length === 0" disabled>
                            No results found
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div *ngFor="let watched of watchedVariables; let i = index; trackBy: trackByIndex" (click)="onClickedRow(watched)">

                <div *ngIf="watched.valueNumericArray.length === 1" class="watched-varibles">
                    <mat-form-field subscriptSizing="dynamic" class="variable-field">
                        <mat-label class="text-elipsis color-blue">{{watched.name}}</mat-label>
                        <input matInput type="text" class="right-align hover-cursor" [value]="watched.getValueWithAppliedResolution()" readonly>
                        <div matTextSuffix>{{watched?.unit}}</div>   
                        <mat-icon matIconSuffix class="remove-icon" (click)="removeWatcher(watched)">cancel</mat-icon>
                    </mat-form-field>
                </div>

                <div *ngIf="watched.valueNumericArray.length > 1" class="watched-varibles">
                    <mat-form-field subscriptSizing="dynamic" class="variable-field">
                        <mat-label class="text-elipsis color-blue">{{watched.name}}</mat-label>
                        <input matInput type="text" [matTooltip]="getValue(watched).toString()" matTooltipShowDelay="500" class="right-align hover-cursor text-elipsis" [value]="getValue(watched)" readonly>
                        <mat-icon matIconSuffix class="remove-icon" (click)="removeWatcher(watched)">cancel</mat-icon>
                    </mat-form-field>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>