<div class="download-offline-container">
    <div>
        <h1>HiCommand</h1>
        <p style="text-align: justify;" i18n="@@download-offline.link-information">Here You can download your
            offline-version of HiCommand</p>
        <a *ngIf="!!href" href="{{ href }}" download="{{ download }}" i18n="@@download-offline.link">Get your file
            here</a>
        <a *ngIf="!!preSignedUrl" href="{{ preSignedUrl }}" target="_blank" i18n="@@download-offline.link">Get your file
            here</a>
    </div>
</div>