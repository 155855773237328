import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatOptionModule } from "@angular/material/core";
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { LocaleService } from "src/app/core/localisation/services/locale/locale.service";

@Component({
    selector: "hic-language-select-dialog",
    templateUrl: "./language-select-dialog.component.html",
    styleUrls: ["./language-select-dialog.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatOptionModule,
        MatListModule
    ],
})
export class LanguageSelectDialogComponent implements OnInit {
    public locales = LocaleService.locales;
    public selectedItem: string;


    constructor(@Inject(MAT_DIALOG_DATA) public data: string) { }

    ngOnInit(): void {
        this.selectedItem = this.data;
    }

    public selectItem(item: string): void {
        this.selectedItem = item;
    }

}
