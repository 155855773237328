import { Inject, Injectable } from "@angular/core";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { HubUrls } from "src/app/globals/urls";
import { SIGNALR_TOKEN } from "src/app/signal-r.provider";
import { ConditionsService } from "../space/services/conditions/conditions.service";
import { MessageService } from "./message.service";
import { GenericMessageDto, GenericMessageType } from "./models/generic-message.dto";

@Injectable({providedIn: "root"})
export class GenericMessageService {
    private hubConnection: HubConnection;

    constructor(
        @Inject(SIGNALR_TOKEN) private signalR: any,
        private conditionsService: ConditionsService, private messageService: MessageService) {
    }

    public connectToHub(): void {
        const connectionId = this.conditionsService.connectionId;
        const builder = this.signalR.HubConnectionBuilder as HubConnectionBuilder;
        this.hubConnection = builder
            .withUrl(`${HubUrls.genericmessage}?connectionId=${connectionId}`)
            .build();

        this.hubConnection.on("OnGenericMessage", (genericMessage: GenericMessageDto) => {
            if (genericMessage.type === GenericMessageType.information) {
                this.messageService.sendMessage(genericMessage.message);
            } else if (genericMessage.type === GenericMessageType.warning) {
                this.messageService.sendWarning(genericMessage.message);
            } else if (genericMessage.type === GenericMessageType.error) {
                this.messageService.sendAlert(genericMessage.message);
            }
        });

        this.hubConnection
            .start()
            .then(() => console.log("Connection started"))
            .catch(_ => console.log("Error while establishing connection"));
    }
}
