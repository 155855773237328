import { Injectable } from "@angular/core";
import { VersionDto } from "../../models/version.dto";
import { VersionManagementHttpService } from "./version-managemnet.http.service";

@Injectable({
    providedIn: "root"
})
export class VersionManagementService {

    constructor(
        private versionManagementHttpService: VersionManagementHttpService) {
    }

    public async checkNewVersionAvailable(): Promise<VersionDto> {
        return await this.versionManagementHttpService.checkNewVersionAvailable();
    }

    public async getReleaseNameAsync(): Promise<string> {
        try {
            const releaseName = await this.versionManagementHttpService.getReleaseName();
            return releaseName.trim();
        } catch (err) {
            return err.message;
        }
    }

    public async getReleaseNotesAsync(): Promise<string> {
        try {
            const releaseNotes = await this.versionManagementHttpService.getReleaseNotes();
            return releaseNotes;
        } catch (err) {
            return err.message;
        }
    }
}
