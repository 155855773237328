<div class="start-page-container">
    <div class="header" i18n>Add Connections</div>

    <div class="start-page-add-connections-container">
        <hic-remote-connection *ngIf="offlineMode === false && remoteAccessAllowed"></hic-remote-connection>
        <hic-blackbaron-connection *ngIf="offlineMode === false && blackBaronAccessAllowed"></hic-blackbaron-connection>
        <hic-system-file-upload></hic-system-file-upload>
    </div>

    <div class="available-connections-section">
        <div class="header" i18n *ngIf="!noConnection">Available connections</div>
        <div class="available-connections-container">
            <hic-available-connections (connectionsEmpty)="noConnections($event)">
            </hic-available-connections>
            <div class="searching" *ngIf="noConnection && offlineMode">

                <mat-spinner diameter="48"></mat-spinner>
            </div>
        </div>
    </div>
</div>
