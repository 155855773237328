import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { Router } from "@angular/router";
import { Observable, Subject, map, startWith, takeUntil } from "rxjs";
import { SearchItem } from "src/app/shared/toolbox/search-bar/search-bar.component";
import { NavigationService } from "../../navigation-core/services/navigation.service";
import { NavigationItem } from "../../navigation-core/models/navigation-item.model";

@Component({
    selector: "hic-navigation-search",
    templateUrl: "./navigation-search.component.html",
    styleUrls: ["./navigation-search.component.scss"]
})
export class NavigationSearchComponent implements OnInit {
    @ViewChild("searchInput") public searchBarElement: ElementRef;

    public navOptionsControl: FormControl = new FormControl();
    public displaySearchBar = false;
    public filteredNavOptions$: Observable<SearchItem[]>;

    public searchItems: SearchItem[] = [];

    public menus$: Observable<NavigationItem[][]>;
    private componentDestroyed$ = new Subject<boolean>();

    constructor(private router: Router, private navigationService: NavigationService, public changeDetector: ChangeDetectorRef) {
        this.menus$ = navigationService.activeMenu$;

        navigationService.activeMenu$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async _menus => {
            const flattenedItems = await navigationService.getFlattenedMenuItemsAsync(true);
            this.searchItems = [];
            for (const item of flattenedItems) {
                if (!item.isHeader) {
                    this.addItemToSearch(item);
                }
            }
        });
    }

    ngOnInit(): void {
        this.setupProductsFilter();
    }

    @HostListener("window:keydown", ["$event"]) public handleKeyPress(event: KeyboardEvent): void {
        if (event.key === "F" && event.ctrlKey && event.shiftKey) {
            this.displaySearch();
        }
    }

    public onAutoCompleteClose(): void {
        this.displaySearchBar = false;
        console.log();
    }

    public onNavigationSearchItemSelected(item: SearchItem): void {
        if (item) {
            this.router.navigateByUrl(item.route);
        }
    }

    public hideSearchBar(): void {
        this.displaySearchBar = false;
    }

    public displaySearch(): void {
        this.displaySearchBar = !this.displaySearchBar;

        this.changeDetector.detectChanges();
        this.searchBarElement.nativeElement.focus();
    }

    public displayNavOption(navOption?: SearchItem): string | undefined {
        return navOption ? navOption.title : undefined;
    }

    public navigateToSelected(event: MatAutocompleteSelectedEvent, inputElement: HTMLInputElement): void {
        this.router.navigateByUrl(event.option.value.route);
        inputElement.blur();
        this.navOptionsControl.setValue("");
    }

    private filterNavOptions(navFilter: SearchItem | string): SearchItem[] {
        if (typeof navFilter === "string") {
            return this.searchItems.filter(option => option.title.toLowerCase().includes(navFilter.toLowerCase()));
        }

        if (typeof navFilter === "object") {
            const test = this.searchItems.filter(option => option.title.toLowerCase().includes(navFilter.title.toLowerCase()));

            return test;
        }
    }

    private setupProductsFilter(): void {
        const initValue = this.navOptionsControl.value ? this.navOptionsControl.value : "";

        this.filteredNavOptions$ = this.navOptionsControl.valueChanges.pipe(
            startWith(initValue),
            map(val => this.filterNavOptions(val))
        );
    }

    private addItemToSearch(item: NavigationItem): void {
        if (item.isTerminal && !item.isHeader && item.route != null) {
            // Add title
            this.addToSearchItems(item.title, item.title, item.route);

            // Add keys
            // if (item.searchKeys) {
            //     for (const searchKey of item.searchKeys) {
            //         this.addToSearchItems(item.title, searchKey, item.route);
            //     }
            // }
        }
    }

    private addToSearchItems(title: string, searchKey: string, route: string): void {
        this.searchItems.push({
            title,
            searchKey,
            route,
        });
    }
}
