import { animate, state, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base-component";
import { UserInformationService } from "src/app/core/security/shared/services/userinformation/userinformation.service";
import { ConditionsService } from "src/app/core/space/services/conditions/conditions.service";
import { InfoDisplayService } from "../info-display.service";
import { NavigationEnd, Router } from "@angular/router";

@Component({
    selector: "hic-info-display",
    templateUrl: "./info-display.component.html",
    styleUrls: ["./info-display.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger("expandPanel", [
            state("collapsed", style({ width: "0" })),
            state("expanded", style({ width: "20em" })),
            transition("collapsed => expanded", animate("300ms ease-in")),
            transition("expanded => collapsed", animate("300ms ease-out"))
        ])
    ]
})
export class InfoDisplayComponent extends BaseComponent implements OnInit, OnDestroy {
    private componentDestroyed$ = new Subject<boolean>();
    public isDimmed = true;
    public showInfoPanel$: Observable<boolean>;
    public panelType$: Observable<"pushing" | "overlapping">;
    public panelState$: Observable<"collapsed" | "expanded">;


    constructor(
        private userInformationService: UserInformationService,
        private infoDisplayService: InfoDisplayService,
        private router: Router,
        conditionsService: ConditionsService,
        changeDetector: ChangeDetectorRef
        
    ) {
        super(conditionsService, changeDetector);
        this.conditionsService.connectionChanged$
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(_ => this.changeDetector.markForCheck());
            
    this.subscribeToRouteChanges();
    this.showInfoPanel$ = this.infoDisplayService.showSection$;
    this.panelType$ = this.infoDisplayService.panelType$;
    this.panelState$ = this.infoDisplayService.panelState$;

    }

    private subscribeToRouteChanges(): void {
        this.router.events.pipe(takeUntil(this.componentDestroyed$)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.infoDisplayService.setPanelState("collapsed");
            }
        });
    }
    public ngOnInit(): void {
        this.changeDetector.markForCheck();
    }

    public ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    public togglePanel(): void {
        this.infoDisplayService.togglePanelState();
    }

    public showHandle(): void {
        this.isDimmed = false;
    }

    public hideHandle(): void {
        this.isDimmed = true;
    }

    public isLoggedIn(): boolean {
        return this.userInformationService.loggedIn;
    }
}
