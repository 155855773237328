<div tabindex="0"
    class="start-page-connection"
    [ngClass]="{
        connected: isActive(availableConnection.id),
        disconnected: availableConnection.state === connectionState.Disconnected,
        connecting: availableConnection.state === connectionState.Connecting
    }"
    id="available-connections-box"
    *ngFor="let availableConnection of availableConnections$ | async; trackBy: trackByConnection">
    <div class="left-side">
        <div class="start-item">
           <span *ngIf="availableConnection.communicationProtocol === communicationProtocols.KvaserNoSession;" class="label" i18n>
                node id
            </span>
            <div *ngIf="availableConnection.communicationProtocol !== communicationProtocols.KvaserNoSession;" class="label" i18n>
                serial number
            </div>
            <div>
                {{ availableConnection.name }}
            </div>
        </div>
        <div class="start-item">
           <div class="label" i18n>
                vehicle id
            </div>
            <div>{{ availableConnection.extraInfo }}</div>
        </div>

        <div class="start-item">
           <div class="label" i18n>
                connection
            </div>
            <div>
                {{ getConnectionName(availableConnection) }}
            </div>
        </div>

        <div class="start-item">
           <div class="label" i18n>
                status
            </div>
            <div>{{ getStatusText(availableConnection.state) }}</div>
        </div>
    </div>

    <div class="divider-container">
        <mat-divider [vertical]="true"></mat-divider>
    </div>

    <div class="connection-button-side">
        <div class="group">
            <div class="column">
               <span *ngIf="availableConnection.state === connectionState.Connected" class="label" i18n="@@start.proceed-to">
                    proceed to
                </span>
               <span *ngIf="availableConnection.state !== connectionState.Connected" class="label" i18n="@@start.connect-and-proceed-to">
                    connect and proceed to
                </span>
                <div class="buttons">
                    <button
                        mat-stroked-button
                        color="primary"
                        disableRipple
                        [disabled]="!isEnabled(availableConnection.state)"
                        (click)="connectToHiCommand(availableConnection)">
                        HiCommand
                    </button>
                    <button *ngIf="hiTestAllowed && offlineMode"
                        mat-stroked-button
                        color="primary"
                        disableRipple
                        [disabled]="!isEnabled(availableConnection.state)"
                        (click)="connectToHiTest(availableConnection)">
                        HiTest
                    </button>
                    <button *ngIf="hiViewAllowed"
                        mat-stroked-button
                        color="primary"
                        disableRipple
                        [disabled]="!isEnabled(availableConnection.state)"
                        (click)="connectToHiView(availableConnection)">
                        HiView
                    </button>
                </div>
            </div>

            <div *ngIf="availableConnection.communicationProtocol === communicationProtocols.Serial" class="column">
               <span class="label" i18n="@@start.baud-rate">
                    baud rate
                </span>
                <mat-form-field appearance="fill">
                    <mat-label>Select</mat-label>
                    <mat-select #baudrate [(ngModel)]="selectedBaudRate" [disabled]="availableConnection.state !== connectionState.Available">
                        <mat-option *ngFor="let baudrate of availableBaudrates" [value]="baudrate.id" (mousedown)="keepFocus($event)">
                            {{ baudrate.text }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngIf="availableConnection.communicationProtocol === communicationProtocols.KvaserNoSession" class="column">
               <span class="label" i18n="@@start.bit-rate">
                    bit rate
                </span>
                <mat-form-field appearance="fill">
                    <mat-label>Select</mat-label>
                    <mat-select [(ngModel)]="selectedBitRate" [disabled]="availableConnection.state !== connectionState.Available">
                        <mat-option *ngFor="let bitRate of availableBitRates" [value]="bitRate.id" (mousedown)="keepFocus($event)">
                            {{ bitRate.text }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="availableConnection.state === connectionState.Connected" class="column disconnect">
            <button mat-button color="primary" disableRipple (click)="disconnect(availableConnection.connectionId)">
                <svg width="44" height="44" fill="none" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2475_15301)">
                            <path
                            d="M20.9992 19.6667C21.0178 18.6951 20.6941 17.748 20.0847 16.9911C19.4753 16.2342 18.619 15.7158 17.6659 15.5267L17.6659 13L16.3326 13L16.3326 15.5533C15.3852 15.7441 14.534 16.2592 13.9255 17.01C13.3171 17.7608 12.9895 18.7003 12.9992 19.6667L12.9992 23L20.9992 23L20.9992 19.6667Z"
                            fill="#37424A" />
                        <path
                            d="M27.6667 30.9933L27.6667 28.5365C28.6173 28.3407 29.4705 27.816 30.0789 27.0529C30.6874 26.2899 31.013 25.3363 30.9996 24.3567L30.9996 21.0385L29.6664 21.0385L29.6664 17.6731C29.6664 17.4946 29.5962 17.3234 29.4712 17.1971C29.3462 17.0709 29.1767 17 28.9999 17C28.8231 17 28.6535 17.0709 28.5285 17.1971C28.4035 17.3234 28.3333 17.4946 28.3333 17.6731L28.3333 21.0385L25.667 21.0385L25.667 17.6731C25.667 17.4946 25.5968 17.3234 25.4718 17.1971C25.3467 17.0709 25.1772 17 25.0004 17C24.8236 17 24.6541 17.0709 24.5291 17.1971C24.4041 17.3234 24.3338 17.4946 24.3338 17.6731L24.3338 21.0385L23.0007 21.0385L23.0007 24.3635C22.9831 25.3448 23.3071 26.3013 23.9161 27.0663C24.5251 27.8313 25.3806 28.3564 26.3336 28.55L26.3336 31L27.6667 30.9933Z"
                            fill="#37424A" />
                    </g>
                    <defs>
                        <clipPath id="clip0_2475_15301">
                            <rect width="24" height="24" fill="white" transform="translate(10 10)" />
                        </clipPath>
                    </defs>
                </svg>
            </button>
        </div>
    </div>
</div>


<hic-fullscreen-spinner [visible]="isConnecting"></hic-fullscreen-spinner>
