import { ChangeDetectorRef, Component, HostListener, ViewChild } from "@angular/core";
import { BaseComponent } from "src/app/base-component";
import { ConnectService } from "src/app/core/connections/serial/services/connect.service";
import { MessageService } from "src/app/core/messages/message.service";
import { ConditionsService } from "src/app/core/space/services/conditions/conditions.service";

@Component({
    selector: "hic-system-file-upload",
    templateUrl: "./system-file-upload.component.html",
    styleUrls: ["./system-file-upload.component.scss"],
    providers: [ConnectService]
})
export class SystemFileUploadComponent extends BaseComponent {
    @ViewChild("fileInput", { static: true }) public fileInput: any;

    private file: File;

    public filePath: string;
    public uploading = false;

    public constructor(
        conditionsService: ConditionsService,
        changeDetector: ChangeDetectorRef,
        private messageService: MessageService,
        private connectService: ConnectService
    ) {
        super(conditionsService, changeDetector);
    }

    @HostListener("dragover", ["$event"]) public onDragOver(ev): void {
        ev.preventDefault();
    }

    @HostListener("dragenter", ["$event"]) public dragenter(ev): void {
        ev.preventDefault();
        ev.stopPropagation();
        const element = document.getElementById("wrapper");
        element.style.backgroundColor = "#E0E0E0";
    }

    @HostListener("dragleave", ["$event"]) public dragleave(ev): void {
        ev.preventDefault();
        ev.stopPropagation();
        const element = document.getElementById("wrapper");
        element.style.backgroundColor = "white";
    }

    @HostListener("drop", ["$event"]) public onDrop(ev): void {
        ev.preventDefault();

        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            [...ev.dataTransfer.items].forEach((item, i) => {
                // If dropped items aren't files, reject them
                if (item.kind === "file") {
                    const file = item.getAsFile();
                    this.file = file;
                }
            });
        } else {
            // Use DataTransfer interface to access the file(s)
            [...ev.dataTransfer.files].forEach((file, i) => {
                this.file = file;
            });
        }

        this.handleDroppedFile();
    }

    @HostListener("click") public onClick(): void {
        this.fileInput.nativeElement.click();
    }

    public handleFileDialogFile(evt: string): void {
        const isSacFile = evt && this.isSacFile(evt);
        const isJsonFile = evt && this.isJsonFile(evt);
        const isAesFile = evt && this.isAesFile(evt);
        if (!this.isAllowedFile(isSacFile, isJsonFile, isAesFile)) {
            return;
        }

        const fi = this.fileInput.nativeElement;
        if (fi.files && fi.files[0]) {
            const fileToUpload: File = fi.files[0];
            if (isSacFile) {
                this.connectToSac(fileToUpload);
            } else if (isJsonFile || isAesFile) {
                this.connectToEvoSystemFile(fileToUpload);
            }
        }
    }

    private handleDroppedFile(): void {
        const isSacFile = this.file && this.isSacFile(this.file.name);
        const isJsonFile = this.file && this.isJsonFile(this.file.name);
        const isAesFile = this.file && this.isAesFile(this.file.name);
        if (!this.isAllowedFile(isSacFile, isJsonFile, isAesFile)) {
            return;
        }

        if (isSacFile) {
            this.connectToSac(this.file);
        } else if (isJsonFile || isAesFile) {
            this.connectToEvoSystemFile(this.file);
        }
    }

    private isSacFile(fileType: string): boolean {
        return fileType.toLowerCase().indexOf("sac") > 0;
    }

    private isJsonFile(fileType: string): boolean {
        return fileType.toLowerCase().indexOf("json") > 0;
    }

    private isAesFile(fileType: string): boolean {
        return fileType.toLowerCase().indexOf("aes") > 0;
    }

    private isAllowedFile(isSacFile: boolean, isJsonFile: boolean, isAesFile: boolean): boolean {
        return isSacFile || isJsonFile || isAesFile;
    }

    private async connectToSac(file: File): Promise<void> {
        this.uploading = true;
        try {
            await this.connectService.connectToSacFile(file);
        } catch (error) {
            this.messageService.sendAlert($localize `:@@could-not-read-sac-file:Could not read the SAC-file`);
        } finally {
            this.uploading = false;
            setTimeout(() => this.filePath = undefined, 0);
        }
    }

    private async connectToEvoSystemFile(file: File): Promise<void> {
        this.uploading = true;
        try {
            await this.connectService.connectToEvoSystemFile(file);
        } catch (error) {
            this.messageService.sendAlert($localize `:@@could-not-read-json-file:Could not read the JSON file`);
        } finally {
            this.uploading = false;
            setTimeout(() => this.filePath = undefined, 0);
        }
    }
}
