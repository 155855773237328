import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Urls } from "src/app/globals/urls";


@Injectable({
    providedIn: 'root'
})
export class SnapshotHttpService {

    constructor(private http: HttpClient) { }

    public createSnapshot(): Observable<boolean> {
        return this.http.get<boolean>(Urls.SNAPSHOT);
    }
}
