import { DialogRef } from "@angular/cdk/dialog";
import { Component, Inject } from "@angular/core";
import { SurveyClientService } from "../services/survey-client.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LocaleService } from "src/app/core/localisation/services/locale/locale.service";
import { SurveyModel } from "../../shared/models/survey.model";
import { SpinnerService } from "src/app/core/dialogs/services/spinner.service";
import { Subject } from "rxjs";
import { SurveyResponseModel } from "../../shared/models/survey-response.model";
import { SurveyQuestion } from "../../shared/models/survey-question.model";
import { TranslationHelper } from "../../shared/helpers/translation.helper";

@Component({
    selector: "app-survey",
    templateUrl: "./survey.component.html",
    styleUrls: ["./survey.component.scss"],
})
export class SurveyComponent {
    private readonly laterAnswerState = "later";

    public userLocale: string;
    public survey: SurveyModel;

    public questionAndResponse: { question: SurveyQuestion, response: SurveyResponseModel }[] = [];

    public hasResponded = false;

    constructor(
        private dialogRef: DialogRef,
        private surveyClientService: SurveyClientService,
        private localeService: LocaleService,
        private spinnerService: SpinnerService,
        @Inject(MAT_DIALOG_DATA) data) {

            this.userLocale = this.localeService.selectedLocale;
            this.survey = data;

            this.setupResponses();
    }

    public close(): void {
        this.dialogRef.close();
    }

    public async later(): Promise<void> {
        const spinner = new Subject<void>();
        this.spinnerService.showSpinnerUntil(spinner);

        this.surveyClientService.saveSurveyAnswer(this.survey, this.laterAnswerState).finally(() => {
            spinner.next();
        });

        this.dialogRef.close();
    }


    public async save(): Promise<void> {
        const spinner = new Subject<void>();
        this.spinnerService.showSpinnerUntil(spinner);

        const responses = this.questionAndResponse.map(qr => qr.response);
        this.surveyClientService.saveSurvey(this.survey, responses).finally(() => {
            this.hasResponded = true;
            spinner.next();
        });
    }

    public get Title(): string {
        return TranslationHelper.getTranslation(this.survey.title, this.userLocale);
    }

    public get Description(): string {
        return TranslationHelper.getTranslation(this.survey.description, this.userLocale);
    }

    public get ThankYouMessage(): string {
        return TranslationHelper.getTranslation(this.survey.thankYouMessage, this.userLocale);
    }

    private setupResponses(): void {
        this.survey.questions.forEach(question => {
            const response = new SurveyResponseModel();
            response.questionId = question.id;
            response.response = "";
            response.timestamp = new Date();

            this.questionAndResponse.push({ question, response });
        });
    }
}
