import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatExpansionPanel } from "@angular/material/expansion";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { BaseComponent } from "src/app/base-component";
import { ControlSystemGeneration } from "src/app/core/connections/enums/control-system-generation.enum";
import { ConditionsService } from "src/app/core/space/services/conditions/conditions.service";
import { SpaceError } from "src/app/overview/errors/shared/shared-errors/active-errors/models/space-error.model";
import { ErrorsService } from "src/app/overview/errors/shared/shared-errors/active-errors/services/errors.service";

@Component({
    selector: "hic-errors-mini-display",
    templateUrl: "errors-mini-display.component.html",
    styleUrls: ["./errors-mini-display.component.scss"]
})
export class ErrorsMiniDisplayComponent extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild("collapsable", { static: true }) public parent: MatExpansionPanel;

    public noInforamtion = $localize `No Info`;

    public get allowOpen(): boolean {
         return this.conditionsService.isDataSourceConnected;
        }
    public activeErrors$ = new Observable<SpaceError[]>();
    public indirectError$ = new Observable<SpaceError[]>();
    public activeErrorSubject = new BehaviorSubject<SpaceError[]>([]);
    public indirectErrorSubject = new BehaviorSubject<SpaceError[]>([]);
    public isEvoSystem: boolean;

    constructor(public conditionsService: ConditionsService,
                public changeDetector: ChangeDetectorRef,
        private errorsService: ErrorsService,
        private router: Router) {
            super(conditionsService, changeDetector);

        this.isEvoSystem = this.conditionsService.controlSystemGeneration === ControlSystemGeneration.Evo;
    }

    public async ngOnInit(): Promise<void> {
        await this.errorsService.subscribe(this.conditionsService.connectionId, this.constructor.name);
        if (this.isEvoSystem) {
            this.activeErrors$ = this.activeErrorSubject.asObservable();
            this.indirectError$ = this.indirectErrorSubject.asObservable();

            this.errorsService.activeErrors$.subscribe(err => {
                const indirectErrors = [];
                const errors = [];
                err.filter(x => {
                    if (x.isIndirectError) {
                        indirectErrors.push(x);
                    } else {
                        errors.push(x);
                    }
                });

                this.activeErrorSubject.next(errors);
                this.indirectErrorSubject.next(indirectErrors);
            });
        } else {
            this.activeErrors$ = this.errorsService.activeErrors$;
        }
    }

    public async ngOnDestroy(): Promise<void> {
        await this.errorsService.unsubscribe(this.constructor.name);
    }

    public clearErrors(): void {
        this.errorsService.clearErrors();
    }

    /**
     * Generates a combined ID from the error ID string and the message string
     * Example: id = "E003" and message = "Emergency stop" compiles into "e003:emergencystop"
     */
     public getCompiledId(spaceError: SpaceError): string {
        return (spaceError.errorId + ":" + spaceError.errorMessage).replace(/\s/g, "").toLowerCase();
    }

    public confirmCriticalErrors(): void {
        this.errorsService.confirmCriticalErrors();
    }

    public routeToErrorPage(): void {
        this.router.navigateByUrl("overview/errors");
    }
    public getIconByErrorSeverity(errorCode: SpaceError): string  {
        if (errorCode.severity === 0) {
            return "info";
        }
        if (errorCode.severity === 1) {
            return "info";
        }
        if (errorCode.severity === 2) {
            return "warning";
        }
    }
    public iconColor(errorCode: SpaceError): string {
        if(errorCode.severity === 0) {
            return "information";
        }   
        if(errorCode.severity === 1) {
            return "warning";
        }
        if(errorCode.severity === 2) {
            return "warning-red";
        }
    }
}
