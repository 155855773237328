<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- Header -->
<div *ngIf="dueWarning">
    <hic-modal-dialog [visible]="true">
        <p>
            This pre-release of HiCommand will stop function in {{daysToDue}} days!
        </p>
    </hic-modal-dialog>
</div>
<iframe id="hidden-logout-window" style="position: absolute; visibility:hidden;width: 0;height: 0;"
    [src]="userInformationService.endSessionIframeURL"></iframe>

<div class="hic-title-bar" #mainmenupanel>
    <div class="logo-section">
        <button *ngIf="(menus$ | async)?.length" class="btn" (click)="toggleShowMegaMenu($event)">
            <mat-icon inline="true" style="font-size: 36px;">menu</mat-icon>
        </button>
        <div class="logo-holder">
            <div>
                <h1 (click)="redirectHome()" class="main-logo">Hi<span style="color:#D52B1F;">Command</span></h1>
            </div>
            <div class="program-version">
                <a *ngIf="isLoggedIn && releaseName" (click)="showReleaseNotes()">
                    {{ releaseName }}
                    <span *ngIf="runningDevSite || runningReviewSite || runningEvoFunctionalSite">[{{ (runningDevSite ?
                        "Dev" : runningReviewSite ? "Review" : runningEvoFunctionalSite ? "evo-functional" : "")
                        }}]</span>
                </a>
            </div>
        </div>

        <div class="survey-available-container">
            <button type="button" (click)="openSurvey(survey)" *ngIf="survey" mat-button color="primary"
                style="color:#FFF;" disableRipple>
                <span i18n>Survey available</span>

                <mat-icon class="rebootIconButton" [svgIcon]="surveyIconName"></mat-icon>
            </button>
        </div>
    </div>
    <div class="connection-container">
        <hic-connection-state (click)="redirectHome()"></hic-connection-state>
    </div>
    <div class="searchbar-container">
        <div *ngIf="showRebootButton === true && (((hideRebootRequiredButton$) | async) === false)">
            <button *ngIf="!rebooting" mat-button color="primary" disableRipple class="icon-button" (click)="reboot()"
                [disabled]="rebooting" #tooltip="matTooltip"
                i18n-matTooltip="You have changed calibrations that requires a reboot"
                matTooltip="You have changed calibrations that requires a reboot">
                <mat-icon class="rebootIconButton" [svgIcon]="rebootIconName"></mat-icon>
            </button>
            <mat-spinner *ngIf="rebooting" class="rebootSpinner" diameter="20" color="warn"></mat-spinner>
        </div>



        <hic-navigation-search class="searchbar" *ngIf="isConnected"></hic-navigation-search>

        <div class="help-button" *ngIf="isLoggedIn">
            <button mat-button color="primary" disableRipple class="icon-button" (click)="openTechnicalDocument()">
                <mat-icon fontSet="material-icons-outlined" style="color: #FFFFFF;">help_outline</mat-icon>
            </button>
        </div>


        <div class="user-information" *ngIf="userInformation !== undefined; else showOnlyShutdown">
            <button mat-button color="primary" disableRipple class="icon-button" (click)="showUserMenu()">
                <mat-icon fontSet="material-icons-outlined" style="color: #FFFFFF;">more_vert</mat-icon>
            </button>
        </div>
        <ng-template #showOnlyShutdown>
            <div class="profile-picture" style="padding: 0.5em 1.1em 0.5em 0.5em; cursor: pointer;">
                <mat-icon inline="true" style="font-size: 42px" (click)="showUserMenu()">power_settings_new</mat-icon>
            </div>
        </ng-template>

        <ng-container *ngIf="userMenuVisible">
            <div class="user-information-menu mat-body-1" hicClickOutside (clickOutside)="hideUserMenu()">
                <div class="user-information-menu-item">
                    {{ userInformation?.friendlyName }}
                </div>
                <div class="user-information-menu-item selectable" *ngIf="!isLoggedIn" (click)="logIn()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span i18n="@@shutdown.login">Login</span>
                </div>

                <ng-container *ngIf="isLoggedIn">
                    <div *ngIf="hasCraneDataAccess" class="user-information-menu-item selectable"
                        (click)="navigateToCraneData()">
                        <mat-icon>how_to_reg</mat-icon>
                        <span i18n="@@shutdown.cranedata-admin">Crane data admin</span>
                    </div>
                    <div *ngIf="hasCraneEcuAndSerialAccess" class="user-information-menu-item selectable"
                        (click)="navigateToCraneEcuAndSerialAdmin()">
                        <mat-icon>how_to_reg</mat-icon>
                        <span i18n="@@shutdown.cranedata-admin">Crane ECU and serial admin</span>
                    </div>
                    <div *ngIf="hasDecryptAccess" class="user-information-menu-item selectable"
                        (click)="chooseEncryptedSystemFile()">
                        <div class="svg-icon" [innerHTML]="userMenuIcons.setupIcon"></div>
                        <span i18n="@@shutdown.decrypt-system-file">Decrypt system file</span>
                    </div>
                    <div *ngIf="hasSuperFeatureTypeAccess" class="user-information-menu-item selectable"
                        (click)="navigateToCraneFeatureAdmin()">
                        <mat-icon>add</mat-icon>
                        <span i18n="@@shutdown.user-crane-features">Crane features</span>
                    </div>
                    <div *ngIf="hasGidListFilesAccess" class="user-information-menu-item selectable"
                        (click)="navigateToGidListFiles()">
                        <mat-icon>cloud_upload</mat-icon>
                        <span i18n="@@shutdown.gidlist-files">GidList files</span>
                    </div>
                    <div *ngIf="hasFlashFilesAccess" class="user-information-menu-item selectable"
                        (click)="navigateToFlashFiles()">
                        <mat-icon>cloud_upload</mat-icon>
                        <span i18n="@@shutdown.flash-files">Flash files</span>
                    </div>
                    <div *ngIf="hasHiSetAdminAccess" class="user-information-menu-item selectable"
                        (click)="navigateToHiSetAdmin()">
                        <mat-icon>how_to_reg</mat-icon>
                        <span i18n="@@shutdown.hiset-admin">HiSet admin</span>
                    </div>
                    <div *ngIf="hasHiSetReportAccess" class="user-information-menu-item selectable"
                        (click)="navigateToHiSetReport()">
                        <mat-icon>description</mat-icon>
                        <span i18n>HiSet report</span>
                    </div>
                    <div *ngIf="hasHiTestAccess" class="user-information-menu-item selectable"
                        (click)="navigateToHiTest()">
                        <mat-icon>how_to_reg</mat-icon>
                        <span i18n="@@shutdown.hitest-admin">HiTest admin</span>
                    </div>
                    <div *ngIf="hasCeCertAccess" class="user-information-menu-item selectable"
                        (click)="navigateToCeCert()">
                        <mat-icon>how_to_reg</mat-icon>
                        <span i18n="@@shutdown.cecert-admin">CE Certs admin</span>
                    </div>
                    <div *ngIf="hasSuperHiTestAccess" class="user-information-menu-item selectable"
                        (click)="navigateToSuperHiTest()">
                        <mat-icon>how_to_reg</mat-icon>
                        <span i18n="@@shutdown.hitest-super-admin">HiTest super admin</span>
                    </div>
                    <div class="user-information-menu-item selectable" *ngIf="isLoggedIn"
                        (click)="navigateToInteractiveDiagrams()">
                        <div class="svg-icon pre-filled" [innerHTML]="userMenuIcons.infoIcon"></div>
                        <span i18n>Interactive diagrams</span>
                    </div>
                    <div *ngIf="hasPlcAdminAccess" class="user-information-menu-item selectable"
                        (click)="navigateToPlcAdmin()">
                        <mat-icon>how_to_reg</mat-icon>
                        <span i18n="@@shutdown.plc-admin">PLC manager</span>
                    </div>
                    <div class="user-information-menu-item selectable" (click)="downloadErrorDescriptionDocument()">
                        <mat-icon>save_alt</mat-icon>
                        <span i18n="@@shutdown.error-descriptions">Error descriptions</span>
                    </div>

                    <div *ngIf="hasPlcCreatorAccess" class="user-information-menu-item selectable"
                        (click)="navigateToPlcCreator()">
                        <div class="svg-icon" [innerHTML]="userMenuIcons.setupIcon"></div>
                        <span i18n="@@shutdown.plc-creator">PLC creator</span>
                    </div>
                    <div *ngIf="hasSystemFilesAccess" class="user-information-menu-item selectable"
                        (click)="navigateToSystemFiles()">
                        <mat-icon>how_to_reg</mat-icon>
                        <span i18n="@@shutdown.system-files">System files directory</span>
                    </div>
                    <div *ngIf="hasVslReportAccess" class="user-information-menu-item user-menu-section-end selectable "
                        (click)="navigateToVslReport()">
                        <mat-icon>description</mat-icon>
                        <span i18n="@@shutdown.vsl-report">VSL report</span>
                    </div>

                    <div *ngIf="hasElevateUserRightsAccess" class="user-information-menu-item selectable"
                        (click)="navigateToElevateUserRights()">
                        <div class="svg-icon">
                            <svg width="18" height="22" viewBox="0 0 18 22" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9 0L0 4V10C0 15.55 3.84 20.74 9 22C14.16 20.74 18 15.55 18 10V4L9 0ZM16 10C16 14.52 13.02 18.69 9 19.93C4.98 18.69 2 14.52 2 10V5.3L9 2.19L16 5.3V10ZM4.41 10.59L3 12L7 16L15 8L13.59 6.58L7 13.17L4.41 10.59Z"
                                    fill="black" fill-opacity="0.6" />
                            </svg>
                        </div>
                        <span i18n>Elevate user rights</span>
                    </div>
                    <div *ngIf="hasUserAdminAccess" class="user-information-menu-item selectable"
                        (click)="navigateToUserAdminOld()">
                        <mat-icon>supervisor_account</mat-icon>
                        <span i18n="@@shutdown.user-admin-old">Group admin</span>
                    </div>
                    <div *ngIf="hasUserAdminNewAccess"
                        class="user-information-menu-item user-menu-section-end selectable"
                        (click)="navigateToUserAdmin()">
                        <mat-icon>supervisor_account</mat-icon>
                        <span i18n="@@shutdown.user-admin">User admin</span>
                    </div>

                    <div class="user-information-menu-item selectable" (click)="openIssueReportLink()">
                        <mat-icon>warning</mat-icon>
                        <span i18n="@@shutdown.report-problem">Report problem</span>
                    </div>

                    <div *ngIf="hasDownloadAccess" class="user-information-menu-item selectable"
                        (click)="navigateToDownloadOffline()">
                        <mat-icon>save_alt</mat-icon>
                        <span i18n="@@shutdown.download-offline">Download offline</span>
                    </div>
                    <div class="user-information-menu-item selectable" (click)="navigateToServiceTools()">
                        <mat-icon>save_alt</mat-icon>
                        <span i18n="@@shutdown.service-tools">Service Tools</span>
                    </div>

                    <div class="user-information-menu-item selectable" (click)="logOut()">
                        <mat-icon>exit_to_app</mat-icon>
                        <span i18n="@@shutdown.logout">Logout</span>
                    </div>
                </ng-container>

                <div class="user-information-menu-item selectable" (click)="showLanguageSelection()">
                    <mat-icon>language</mat-icon>
                    <span i18n>Change Language</span>
                </div>

                <div *ngIf="offlineVersion" class="user-information-menu-item selectable" (click)="powerOff()">
                    <mat-icon>power_settings_new</mat-icon>
                    <span i18n="@@shutdown.shutdown-program">Shutdown</span>
                </div>

            </div>
        </ng-container>
    </div>
</div>
<div *ngIf="showMegaMenu">
    <hic-mega-menu (toggleShowMegaMenuEvent)="toggleShowMegaMenu($event)">
    </hic-mega-menu>
</div>
<div *ngIf="areReleaseNotesVisible" class="hic-darkbox">
    <div class="release-notes-box">
        <div class="close-button">
            <button (click)="hideReleaseNotes()" i18n-title title="Close">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14 1.415L12.585 0L7 5.585L1.415 0L0 1.415L5.585 7L0 12.585L1.415 14L7 8.415L12.585 14L14 12.585L8.415 7L14 1.415Z"
                        fill="black" fill-opacity="0.87" />
                </svg>
            </button>
        </div>
        <div class="release-notes">
            <markdown [data]="releaseNotes"></markdown>
        </div>
    </div>
</div>
<!-- File to decrypt dialog -->
<div class="hic-darkbox" *ngIf="showDecryptDialog">
    <div class="choose-file">
        <div class="file">
            <input type="file" [accept]="allowedFileEndingsDecrypt" (change)="decryptSystemFile($event?.target?.files)"
                class="filestyle" data-classButton="btn btn-primary" data-input="false" data-classIcon="icon-plus" />
        </div>
        <div class="button" (click)="hideDecryptDialog()">
            <span i18n="@@cancel">Cancel</span>
        </div>
    </div>
</div>