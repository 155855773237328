<mat-form-field  *ngIf="displaySearchBar" subscriptSizing=dynamic class="search-field small-padding" appearance="fill">
    <mat-label class="mat-label" i18n>Search</mat-label>
    <input #searchInput type="text" placeholder="Select product..." matInput [formControl]="navOptionsControl" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayNavOption" (closed)="hideSearchBar()" (optionSelected)="navigateToSelected($event, searchInput)">
        <mat-option *ngFor="let navOption of filteredNavOptions$ | async" name="product" [value]="navOption"  (click)="onNavigationSearchItemSelected(navOption)">
            {{ navOption.title }}
        </mat-option>
        <mat-option *ngIf="(filteredNavOptions$ | async)?.length === 0" disabled>
            No matches found
          </mat-option>
    </mat-autocomplete>
    <button mat-button color="primary" matSuffix disableRipple class="icon-button" (click)="hideSearchBar()" >
        <mat-icon fontSet="material-icons-outlined" style="color: #FFFFFF;">search</mat-icon>
    </button>
</mat-form-field>

<button *ngIf="!displaySearchBar" mat-button color="primary" disableRipple class="icon-button" (click)="displaySearch()">
    <mat-icon fontSet="material-icons-outlined" style="color: #FFFFFF;">search</mat-icon>
</button>

