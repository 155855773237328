<div class="remote-connection-wrapper">
    <span class="label" i18n="@@remote-connection">REMOTE CONNECTION</span>
    <div class="input-row">
        <mat-form-field appearance="fill" class="remote-connection-field" floatLabel="always">
            <mat-label>Insert crane serial number</mat-label>
            <input matInput type="text" [(ngModel)]="selectedGateway" (ngModelChange)="onInputChanges($event)" [maxlength]="inputMaxLength" />
            <mat-hint *ngIf="(validGateway$ | async) === false && !isConnecting" i18n="@@start.serial-not-allowed">
                You are not allowed to access this serial number
            </mat-hint>

            <mat-hint *ngIf="isConnecting"> <span i18n>Connecting</span>... </mat-hint>
            <mat-hint *ngIf="gatewayInputTooLong" i18n="@@start.serialno-to-long">
                Serial number is to long
            </mat-hint>
        </mat-form-field>

        <button
            mat-flat-button
            color="primary"
            disableRipple
            (click)="remoteHighSpeedConnect()"
            [disabled]="(canConnectToGateway$ | async) === false || gatewayInputTooLong">
            <span i18n> ADD CONNECTION </span>
        </button>
    </div>
</div>
