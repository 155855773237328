import { ChangeDetectorRef, Component } from "@angular/core";
import { Subject, take, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base-component";
import { GenericMessageService } from "src/app/core/messages/generic-message.service";
import { SystemEventService } from "src/app/core/messages/system-event.service";
import { UserInformationService } from "src/app/core/security/shared/services/userinformation/userinformation.service";
import { ConditionsService } from "src/app/core/space/services/conditions/conditions.service";
import { RemoteConnectionService } from "src/app/start/services/remote-connection/remote-connection.service";
import { OnDestroy, OnInit } from "@angular/core";

@Component({
    selector: "hic-blackbaron-connection",
    templateUrl: "./blackbaron-connection.component.html",
    styleUrls: ["./blackbaron-connection.component.scss"]
})
export class BlackBaronConnectionComponent extends BaseComponent implements OnDestroy, OnInit {
    public selectedBlackBaron = "";
    public isConnecting = false;
    public salesforceUserId ="";
    private destroyed$ = new Subject<any>();

    public inputMaxLength = 100;
    public inputTooLong = false;

    constructor(
        public userInformationService: UserInformationService,
        conditionsService: ConditionsService,
        private remoteConnectionService: RemoteConnectionService,
        changeDetector: ChangeDetectorRef,
        private genericMessageService: GenericMessageService,
        private systemEventService: SystemEventService
    ) {
        super(conditionsService, changeDetector);
    }

    public async ngOnInit(): Promise<void> {
        this.userInformationService.onUserInformation$.pipe(takeUntil(this.destroyed$)).subscribe(userInformation => {
            if (this.selectedBlackBaron === "") {
                this.selectedBlackBaron = userInformation.salesForceUserId;
            }
        });
        await this.userInformationService.getUserInformation();
    }

    public ngOnDestroy(): void {
        this.destroyed$.next(true);
    }

    public onInputChanges($event: string): void {
        this.inputTooLong = $event.length >= this.inputMaxLength;
    }

    public blackBaronConnect(): void {

        this.isConnecting = true;
        this.remoteConnectionService.blackBaronConnectionResult.subscribe(connected => {
            this.isConnecting = false;
            if (connected) {
                this.genericMessageService.connectToHub();
                this.systemEventService.connectToHub();
            }
        });

        this.remoteConnectionService.createBlackBaronRemoteConnection(this.selectedBlackBaron);
    }

}
