import { Component } from '@angular/core';
import { ConnectionStatusHubService } from '../services/connection-status.hub.service';
import { ConnectionStateDto } from '../models/connection-state-dto.model';
import { Observable } from 'rxjs';
import { ConditionsService } from 'src/app/core/space/services/conditions/conditions.service';
import { CommunicationProtocol } from "src/app/core/connections/enums/communication-protocol.enum";
import { ConnectionData } from "src/app/core/connections/models/connection-data.model";
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { ConnectionStateIcons } from './connection-state-icons';


@Component({
  selector: 'hic-connection-state',
  templateUrl: './connection-state.component.html',
  styleUrls: ['./connection-state.component.scss']
})
export class ConnectionStateComponent {

  public connectionState$: Observable<ConnectionStateDto>;
  public connectionChanged$: Observable<ConnectionData>;
  public connectionType = CommunicationProtocol;

  constructor(
    private connectionStatusHubService: ConnectionStatusHubService,
    private conditionsService: ConditionsService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) { 
    this.connectionState$ = connectionStatusHubService.connectionState$;
    this.connectionChanged$ = conditionsService.connectionChanged$;

    iconRegistry.addSvgIconLiteral("no-connection", sanitizer.bypassSecurityTrustHtml(ConnectionStateIcons.CONNECTION_STATUS_NO_CONNECTION))
    iconRegistry.addSvgIconLiteral("connection-file", sanitizer.bypassSecurityTrustHtml(ConnectionStateIcons.CONNECTION_STATUS_FILE))
    iconRegistry.addSvgIconLiteral("connection-remote", sanitizer.bypassSecurityTrustHtml(ConnectionStateIcons.CONNECTION_STATUS_REMOTE))
    iconRegistry.addSvgIconLiteral("connection-cable", sanitizer.bypassSecurityTrustHtml(ConnectionStateIcons.CONNECTION_STATUS_CABLE))
    iconRegistry.addSvgIconLiteral("connection-error", sanitizer.bypassSecurityTrustHtml(ConnectionStateIcons.CONNECTION_STATUS_ERROR))
  }
}
