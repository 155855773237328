import { Inject, Injectable } from "@angular/core";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { Subject } from "rxjs";
import { SIGNALR_TOKEN } from "src/app/signal-r.provider";
import { ConditionsService } from "../space/services/conditions/conditions.service";
import { SystemEventMessageDto } from "./models/system-event.dto";
import { HubUrls } from "src/app/globals/urls";

@Injectable({
    providedIn: "root"
})
export class SystemEventService {
    private hubConnection: HubConnection;

    private systemEventSubject = new Subject<SystemEventMessageDto>();
    public onSystemEvent$ = this.systemEventSubject.asObservable();

    constructor(
        @Inject(SIGNALR_TOKEN) private signalR: any,
        private conditionsService: ConditionsService) {
    }

    public connectToHub() {
        const connectionId = this.conditionsService.connectionId;
        const builder = <HubConnectionBuilder>this.signalR.HubConnectionBuilder;
        this.hubConnection = builder
            .withUrl(`${HubUrls.systemevents}?connectionId=${connectionId}`)
            .build();

        this.hubConnection.on("OnSystemEvent", (systemEvent: SystemEventMessageDto) => {
            this.systemEventSubject.next(systemEvent);
        });

        this.hubConnection
            .start()
            .then(() => console.log("Connection started"))
            .catch(_ => console.log("Error while establishing connection"));
    }
}
