import { NgModuleRef, enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppMode } from "src/app/core/application/enums/app-mode.enum";
import { APP_MODE } from "src/app/core/application/tokens/app-mode.token";
import { environment } from "../../../environments/environment";
import { OnlineModule } from "./online.module";

if (environment.production) {
    enableProdMode();
    if (window) {
        window.console.log = (): void => { /* Do nothing */ };
    }
}

const providers = [
    { provide: APP_MODE, useValue: AppMode.online }
];

const bootstrap = ():Promise<NgModuleRef<OnlineModule>> => platformBrowserDynamic(providers)
    .bootstrapModule(OnlineModule);

bootstrap().catch(err => console.log(err));
