import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { BrowserModule } from "@angular/platform-browser";
import { MARKED_OPTIONS, MarkdownModule } from "ngx-markdown";
import { CoreModule } from "src/app/core/core.module";
import { coreProviders } from "src/app/core/providers/core-providers.module";
import { DevModule } from "src/app/dev/dev.module";
import { InteractiveDiagramsModule } from "src/app/interactive-diagrams/interactive-diagrams.module";
import { NavigationModule } from "src/app/navigation/navigation.module";
import { DialogModule } from "src/app/shared/material-dialog/dialog.module";
import { SharedModule } from "src/app/shared/shared.module";
import { SideMenuModule } from "src/app/side-menu/side-menu.module";
import { SurveyClientModule } from "src/app/survey/client/survey-client.module";
import { WINDOW_TOKEN, windowProvider } from "src/app/token-providers/window-provider";
import { markedOptionsFactory } from "../marked-options.factory";
import { StartPageModule } from "../start-page/start-page.module";
import { AppOnlineComponent } from "./app-online/app-online.component";
import { DownloadOfflineComponent } from "./download-offline/Components/download-offline.component";
import { HiSetReportComponent } from "./hiset-report/hiset-report.component";
import { OnlineLoginCallbackComponent } from "./online-login-callback/online-login-callback.component";
import { OnlineRoutingModule } from "./online-routing.module";

@NgModule({ declarations: [
        AppOnlineComponent,
        OnlineLoginCallbackComponent,
        DownloadOfflineComponent,
        HiSetReportComponent,
    ],
    bootstrap: [AppOnlineComponent], imports: [BrowserModule,
        MarkdownModule.forRoot({
            markedOptions: {
                provide: MARKED_OPTIONS,
                useFactory: markedOptionsFactory,
            },
        }),
        CoreModule,
        FormsModule,
        NavigationModule,
        OnlineRoutingModule,
        SharedModule,
        SideMenuModule,
        DevModule,
        StartPageModule,
        DialogModule,
        InteractiveDiagramsModule,
        MatButtonModule,
        SurveyClientModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        ReactiveFormsModule], providers: [
        ...coreProviders,
        { provide: WINDOW_TOKEN, useFactory: windowProvider },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class OnlineModule { }
