import { MarkedRenderer, MarkedOptions } from "ngx-markdown";

export function markedOptionsFactory(): MarkedOptions {
    const renderer = new MarkedRenderer();

    renderer.heading = (text: string, level: number) => {
      return `<h${level} class="md-heading">${ text }</h${level}>`;
    };

    return {
      renderer: renderer,
      gfm: true,
      breaks: true,
      pedantic: false,
      // smartLists: true,
      // smartypants: false,
    };
}
