<div hicClickOutside (clickOutside)="closeMenu()" class="hic-mega-menu" (keydown.escape)="closeMenu()">
    <div *ngFor="let section of activeMenu$ | async" class="column">
        <div *ngFor="let menuItem of section">
            <div *ngIf="menuItem.isHeader" class="{{'level-' + menuItem.level}}" class="header">
                {{menuItem.title}}
            </div>
            <a *ngIf="!menuItem.isHeader" routerLinkActive="current-page"  class="{{'level-' + menuItem.level}}" [routerLink]="menuItem.route">
                {{menuItem.title}}
            </a>
        </div>
    </div>
</div>
