import { Component, EventEmitter, OnDestroy, Output } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter, Observable, Subject, takeUntil } from "rxjs";
import { NavigationItem } from "../../navigation-core/models/navigation-item.model";
import { NavigationService } from "../../navigation-core/services/navigation.service";

@Component({
    selector: "hic-mega-menu",
    templateUrl: "./mega-menu.component.html",
    styleUrls: ["./mega-menu.component.scss"]
})
export class MegaMenuComponent implements OnDestroy {
    @Output() public toggleShowMegaMenuEvent = new EventEmitter<boolean>();
    private componentDestroyed$ = new Subject<boolean>();
    public activeMenu$: Observable<NavigationItem[][]>;
    constructor(public navigationService: NavigationService, private router: Router) {
        this.listenForNavigationEvent(router);
        this.activeMenu$ = navigationService.activeMenu$;

    }

    public closeMenu(): void {
        this.toggleShowMegaMenuEvent.emit(false);
    }
    public ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    private listenForNavigationEvent(router: Router): void {
        router.events.pipe(
                filter(x => x instanceof NavigationEnd),
                takeUntil(this.componentDestroyed$)
            )
            .subscribe(x => {
                this.closeMenu();
            });
    }
}
