<div class="remote-connection-wrapper">
    <span class="label" i18n="@@remote-connection">BLACKBARON CONNECTION</span>
    <div class="input-row">
        <mat-form-field appearance="fill" class="remote-connection-field" floatLabel="always">
            <mat-label>Insert blackbaron number</mat-label>
            <input matInput type="text" [(ngModel)]="selectedBlackBaron" (ngModelChange)="onInputChanges($event)" [maxlength]="inputMaxLength" />
            <mat-hint *ngIf="isConnecting"> <span i18n>Connecting</span>... </mat-hint>
            <mat-hint *ngIf="inputTooLong" i18n="@@start.blackbaron-number-to-long">
                Blackbaron number is to long
            </mat-hint>
        </mat-form-field>

        <button
            mat-flat-button
            color="primary"
            disableRipple
            (click)="blackBaronConnect()"
            [disabled]="!selectedBlackBaron?.length || inputTooLong">
            <span i18n> ADD CONNECTION </span>
        </button>
    </div>
</div>
