import { Injectable, EventEmitter } from "@angular/core";
import { SnapshotHttpService } from './snapshot.http.service';

@Injectable({
    providedIn: 'root'
})
export class SnapshotService {

    public snapshotReady = new EventEmitter<boolean>();

    constructor(
        private snapshotHttpService: SnapshotHttpService) {
    }

    public createSnapshot() {
        this.snapshotHttpService.createSnapshot().subscribe(res => {
            this.snapshotReady.emit(res);
            // alert("Snapshot done");
        }
        );
    }

}
