import { Component, ChangeDetectorRef, ViewChild, Input } from "@angular/core";
import { BaseComponent } from "src/app/base-component";
import { CollapsablePanelComponent } from "src/app/shared/toolbox/collapsable-panel/components/collapsable-panel.component";
import { ConditionsService } from "src/app/core/space/services/conditions/conditions.service";

@Component({
    selector: "hic-criterias-mini-display",
    templateUrl: "./criterias-mini-display.component.html"
})
export class CriteriasMiniDisplayComponent extends BaseComponent {
    @ViewChild(CollapsablePanelComponent, { static: true }) parent: CollapsablePanelComponent;
    public get allowOpen(): boolean { return this.conditionsService.isDataSourceConnected; }

    constructor(public conditionsService: ConditionsService,
                changeDetector: ChangeDetectorRef) {
            super(conditionsService, changeDetector);
    }

}
