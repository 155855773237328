import { Component, Inject, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { WINDOW_TOKEN } from "src/app/token-providers/window-provider";

@Component({
    selector: "hic-online-login-callback",
    templateUrl: "./online-login-callback.component.html"
})
export class OnlineLoginCallbackComponent implements OnDestroy {
    private destroyed$ = new Subject<any>();

    constructor(private route: ActivatedRoute, @Inject(WINDOW_TOKEN) private windowToken: Window) {}

    ngOnInit() {
        this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
            this.windowToken.location.href = "http://localhost:5000/api/identity/loginexternalcallback?token=" + params["tokenValue"];
        });
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
    }
}
