export class BaudRate {
    id: number;
    text: string;

    constructor(id: number, text: string) {
        this.id = id;
        this.text = text;
    }

    public static getBaudRateList(): BaudRate[] {
        return [
            new BaudRate(BaudRateIdentifier.baudrate_19200, "19200 bps"),
            new BaudRate(BaudRateIdentifier.baudrate_38400, "38400 bps"),
            new BaudRate(BaudRateIdentifier.baudrate_57600, "57600 bps")
        ];
    }
}

export enum BaudRateIdentifier {
    baudrate_19200,
    baudrate_38400,
    baudrate_57600
}
