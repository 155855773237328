import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Urls } from "src/app/globals/urls";
import { CraneSerialNumberValidationModel } from "../models/crane-serial-number-validation-model";

@Injectable({
  providedIn: "root"
})
export class CraneSerialNumberHttpService {

  constructor(
      private httpClient: HttpClient,
  ) { }

  public validateSerialNumber(serialNumber: string): Observable<CraneSerialNumberValidationModel> {
    return this.httpClient.get<CraneSerialNumberValidationModel>(Urls.CRANE_SERIAL_NUMBER + "/validate", { params: {serialNumber} } );
  }
}
