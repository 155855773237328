<div class="report-container flex-row">
    <div class="reports-container">
        <div class="header">
            <h3 i18n> HiSet Reports</h3>
        </div>
        <div class="report-buttons">
            <button mat-flat-button color="primary" (click)="getUsedParameters()" i18n>Used Parameters Report</button>
            <button mat-flat-button color="primary" (click)="getUnusedGids()" i18n>Unused Gids Report</button>
            <button mat-flat-button color="primary" (click)="getOutOfRangeParameters()" i18n>Parameters Out Of Range Report</button>
        </div>

        <div class="header">
            <div i18n>Select a product to generate its report</div>
                <form>
                    <mat-form-field>
                    <mat-label>Products</mat-label>
                    <input type="text" placeholder="Select product..." matInput [formControl]="myControl" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayProductName" (optionSelected)="getProductReport($event)">
                            <mat-option *ngFor="let product of filteredProducts$ | async" [value]="product">
                            {{ product.name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </form>
            </div>
    </div>
</div>

<hic-fullscreen-spinner [visible]="showDarkbox" [text]="darkboxMessage"></hic-fullscreen-spinner>
