import { Inject, Injectable } from "@angular/core";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { BehaviorSubject } from "rxjs";
import { HubUrls } from "src/app/globals/urls";
import { ISignalRWrapper, SIGNALR_TOKEN } from "src/app/signal-r.provider";

@Injectable({providedIn: "root"})
export class ApplicationStateHubService {
    private hubTopics: {[key: string]: string} = {
        isGidListOverridden: "ReceiveIsGidListOverridden",
    };

    private hubConnection: HubConnection;
    private isGidListOverriddenSubject = new BehaviorSubject<boolean>(false);
    public isOnline = true;
    public isGidListOverridden$ = this.isGidListOverriddenSubject.asObservable();

    constructor(@Inject(SIGNALR_TOKEN) protected signalR: ISignalRWrapper) {
        const builder = this.signalR.HubConnectionBuilder as HubConnectionBuilder;
        this.hubConnection = builder.withUrl(HubUrls.applicationState).build();
        this.hubConnection.on(this.hubTopics.isGidListOverridden, (isOverridden: boolean) => {
            this.isGidListOverriddenSubject.next(isOverridden);
        });
        this.hubConnection.start();
    }
}
