<ng-container *ngIf="(connectionChanged$ | async) as connectionChanged">
  <ng-container *ngIf="connectionChanged.isConnected">

    <ng-container *ngIf="(connectionState$ | async) as connectionState">
      <div *ngIf="connectionState.timeDifference < 10000" class="connection-state" [ngClass]="connectionState.timeDifference <= 5000 ? 'connection-connected' : 'connection-unstable'">
        <span class="mat-overline">connected</span>
        <mat-icon *ngIf="connectionState.protocol === connectionType.SystemFile" svgIcon="connection-file"></mat-icon>
        <mat-icon *ngIf="connectionState.protocol === connectionType.MQTT" svgIcon="connection-remote"></mat-icon>
        <mat-icon *ngIf="connectionState.protocol === connectionType.Can" svgIcon="connection-cable"></mat-icon>
      </div>

      <div *ngIf="connectionState.timeDifference >= 10000" class="connection-state connection-error">
        <span class="mat-overline">connection error</span>
        <mat-icon svgIcon="connection-error"></mat-icon>
      </div>
    </ng-container>

  </ng-container>

  <ng-container *ngIf="!connectionChanged.isConnected">
    <div class="connection-state no-connection">
      <span class="mat-overline">no connection</span>
      <mat-icon svgIcon="no-connection"></mat-icon>
    </div>
  </ng-container>
</ng-container>
