import { Component, OnInit } from "@angular/core";
import { DownloadOfflineService } from "../Services/download-offline.service";

@Component({
    selector: "hic-download-offline-setup",
    templateUrl: "./download-offline.component.html",
    styles: [`
        .download-offline-container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50%;

            h1 {
                display: flex;
                justify-content: center;
            }
        }
        `]
})

export class DownloadOfflineComponent implements OnInit {
    public href: string = "";
    public download: string = "";
    public preSignedUrl: string = "";

    constructor(private downloadOfflineService: DownloadOfflineService) {}

    public async ngOnInit(): Promise<void> {
        const downloadUrl = await this.downloadOfflineService.getDownloadUrl();
        this.href = downloadUrl.href;
        this.download = downloadUrl.download;
        this.preSignedUrl = downloadUrl.preSignedUrl;
    }
}
