export class SurveyResponseModel {
    public id: any;

    public questionId: any;
    public response: string;
    public timestamp: Date;
    public location: string;

    /** Set in backend */
    public anonymousUserInformation: any = null;
}