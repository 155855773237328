import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoggedOutComponent } from "src/app/core/security/logged-out/components/logged-out.component";
import { LogoutComponent } from "src/app/core/security/login/components/logout.component";
import { RouteAllowedGuard } from "src/app/core/security/shared/guards/route-allowed.guard";
import { environment } from "src/environments/environment";
import { AuthorizationComponent } from "../authorization/authorization.component";
import { RedirectGuard } from "../redirect.guard";
import { StartPageComponent } from "./components/start-page.component";

const routes: Routes = [
    { path: "start", component: StartPageComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { enableTracing: false })
    ],
    exports: [RouterModule]
})
export class StartPageRoutingModule { }
