<div class="errors-container">
    <div class="active-errors-box">
        <mat-accordion [multi]="true">
            <mat-expansion-panel #collapsable [expanded]="true">
                <mat-expansion-panel-header>Errors</mat-expansion-panel-header>
                <div class="error-codes">
                    <div *ngFor="let error of activeErrors$ | async">
                        <div class="error-box">
                            <div class="error-type-icon">
                                <mat-icon class="icon" [ngClass]="iconColor(error)">{{getIconByErrorSeverity(error)}}</mat-icon>
                            </div>
                            <div class="error-code-info">
                                <span class="header">{{ error.errorId }}</span>
                                <span class="text">
                                    <span>{{  error.errorMessage ?  error.errorMessage : noInforamtion }}</span>
                                </span>
                            </div>
                            <div class="error-info-button">
                                <div class="info-circle-container" (click)="routeToErrorPage()">
                                    <mat-icon class="info-icon">info</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- TODO! Implement the functionality to evo aswell (HIAA-8804)-->
                <div *ngIf="!isEvoSystem"  class="button-row">
                    <button mat-flat-button color="primary" class="button" disableRipple (click)="clearErrors()" i18n="@@errors.button.clear">clear errors</button>
                    <button mat-stroked-button color="primary" class="button" disableRipple (click)="confirmCriticalErrors()" i18n="@@errors.button.acc">acc. critical</button>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="indirect-errors-box" *ngIf="isEvoSystem">
        <mat-accordion [multi]="true">
            <mat-expansion-panel #collapsable>
                <mat-expansion-panel-header>Indirect errors</mat-expansion-panel-header>
                <div class="error-codes">
                    <div *ngFor="let error of indirectError$ | async">
                        <div class="error-box">
                            <div class="error-type-icon">
                                <mat-icon class="icon" [ngClass]="iconColor(error)">{{getIconByErrorSeverity(error)}}</mat-icon>
                            </div>
                            <div class="error-code-info">
                                <span class="header">{{ error.errorId }}</span>
                                <span class="text">
                                    <span>{{  error.errorMessage ?  error.errorMessage : noInforamtion }}</span>
                                </span>
                            </div>
                            <div class="error-info-button">
                                <div class="info-circle-container" (click)="routeToErrorPage()">
                                    <mat-icon class="info-icon">info</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>