<h2>
    {{ Title}}
</h2>

<p *ngIf="Description">
    {{ Description }}
</p>

<!-- Rating -->
<ng-container *ngIf="question.questionType === QuestionTypeEnum.Rating">
    <div class="options-wrapper">
        <label *ngFor="let smiley of smileys"
            [for]="smiley"
            class="smiley-img"
            [class]="'label-' + smiley"
            [class.smiley-checked]="getValueFromSmiley(smiley) === response.response"
            >
            <input
                type="radio"
                name="smiley"
                [id]="smiley"
                (alt)="smiley"
                [value]="getValueFromSmiley(smiley)"
                [(ngModel)]="response.response"
            >
        </label>
    </div>
</ng-container>

<!-- Text input -->
<ng-container *ngIf="question.questionType === QuestionTypeEnum.TextInput">
    <mat-form-field style="width:100%;">
        <textarea matInput [(ngModel)]="response.response"></textarea>
    </mat-form-field>
</ng-container>