import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { CraneSerialNumberValidationModel, ValidationStatus } from "../models/crane-serial-number-validation-model";
import { CraneSerialNumberHttpService } from "./crane-serial-number-http.service";

@Injectable({
    providedIn: "root"
})
export class CraneSerialNumberService {

    constructor(
        private httpService: CraneSerialNumberHttpService
    ) { }

    public validateSerialNumber(serialNumber: string): Observable<CraneSerialNumberValidationModel> {
         return this.httpService.validateSerialNumber(serialNumber).pipe(
            catchError(() => {
                return of({ status: ValidationStatus.NoRuleMatch });
            }),
        );
    }
}
