import { Injectable } from "@angular/core";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { HubUrls } from "src/app/globals/urls";
import { ButtonType } from "../../dialogs/models/button-type.enum";
import { DialogResult } from "../../dialogs/models/dialog-result.enum";
import { DialogService } from "../../dialogs/services/dialog.service";
import { MessageService } from "../../messages/message.service";
import { CommunicationError, SetCraneSerialRequest } from "../models/connection-message.model";

@Injectable({providedIn: "root"})
export class ConnectionMessageService {
    private hubConnection: HubConnection;

    private constructor(private dialogService: DialogService, private messageService: MessageService) {}

    public connectToHub(): void {
        this.disconnectFromHub();
        const connectionId = sessionStorage.getItem("connectionId");
        this.hubConnection = new HubConnectionBuilder().withUrl(HubUrls.connectionMessage + "?connectionId=" + connectionId).build();

        this.hubConnection.on("ReceiveCommunicationErrorAsync", this.handleReceiveCommunicationError);
        this.hubConnection.on("ReceiveSetCraneSerialAsync", this.handleSetCraneSerial);

        this.hubConnection
            .start()
            .then(() => console.log("Connection started"))
            .catch(err => console.log("Error while establishing connection"));
    }

    public disconnectFromHub(): void {
        this.hubConnection?.off("ReceiveCommunicationErrorAsync", this.handleReceiveCommunicationError);
        this.hubConnection?.off("ReceiveSetCraneSerialAsync", this.handleSetCraneSerial);
        this.hubConnection = undefined;
    }

    private handleReceiveCommunicationError = async (communicationError: CommunicationError): Promise<void> => {
        this.messageService.sendAlert(`${communicationError.cause}: ${communicationError.message}`);
    };

    private handleSetCraneSerial = async (setCraneSerialRequest: SetCraneSerialRequest): Promise<void> => {
        await this.hubConnection.invoke("ClearCache");
        const response = await this.dialogService.showInputDialogAsync({
            content: $localize `Please enter the crane's serial number`,
            confirmativeButton: ButtonType.save,
            dismissiveButton: ButtonType.cancel,
        },
        {
            inputType: "text",
            defaultValue: setCraneSerialRequest.suggestedSerialNumber,
        });

        if (response === DialogResult.dismiss) {
            return;
        }

        const verify = await this.dialogService.showModalDialogAsync({
            content: $localize `Are You sure that the cranes serial number should be\nset to ${response}?`,
            confirmativeButton: ButtonType.yes,
            dismissiveButton: ButtonType.cancel,
        });

        if (verify === DialogResult.dismiss) {
            return;
        }

        await this.hubConnection.invoke("SetCraneSerial", setCraneSerialRequest.craneId, response);
};

}
