<mat-dialog-content class="content" *ngIf="!hasResponded">
    <div class="survey-header">
        <h1 *ngIf="Title && Title.length > 0">
            {{ Title }}
        </h1>
    
        <p *ngIf="Description && Description.length > 0">
            {{ Description}}
        </p>
    </div>

    <ng-container *ngFor="let question of questionAndResponse">
        <app-survey-question
            [question]="question.question"
            [response]="question.response"
            [locale]="userLocale"
        ></app-survey-question>
    </ng-container>
</mat-dialog-content>

<mat-dialog-content *ngIf="hasResponded">
    <div class="icon-container">
        <mat-icon style="color:green">done_outline</mat-icon>
    </div>

    <h1 i18n>
        Thank you for your feedback!
    </h1>

    <p i18n>
        Your feedback helps us improve HiCommand.
    </p>
</mat-dialog-content>

<mat-dialog-actions class="survey-buttons" align="end">
    <ng-container *ngIf="!hasResponded">
        <button mat-flat-button disableRipple (click)="close()" i18n>Later</button>
        <button mat-flat-button disableRipple color="primary" (click)="save()" i18n>Submit</button>
    </ng-container>

    <ng-container *ngIf="hasResponded">
        <button mat-flat-button disableRipple color="primary" (click)="close()" i18n>Got it!</button>
    </ng-container>
</mat-dialog-actions>
