import { Injectable } from "@angular/core";
import { ChannelInformation } from "src/app/core/connections/serial/models/channel-information.interface";
import { CanInterfaceHttpService } from "./can-interface.http.service";

@Injectable()
export class CanInterfaceService {
  public availableCanInterfaces: ChannelInformation[] = [];

  constructor(private canInterfaceHttpService: CanInterfaceHttpService) {
  }

  public async getCanInterfaceInformationAsync(): Promise<ChannelInformation[]> {
    try {
      this.availableCanInterfaces = await this.canInterfaceHttpService.getCanInterfaceInformation().toPromise();
    } finally {
      return this.availableCanInterfaces;
    }
  }
}
