import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Urls } from "src/app/globals/urls";

@Injectable({
    providedIn: 'root'
})
export class DueDateHttpService {
    constructor(private http: HttpClient) { }

    public GetDueSoon(): Observable<boolean> {
        return this.http.get<boolean>(Urls.DUE_DATE_DUE_SOON);
    }

    public GetDueNow(): Observable<boolean> {
        return this.http.get(Urls.DUE_DATE_DUE_NOW, { responseType: 'text' }).pipe(map(due => {
            if (due === '1') {
                return true;
            } else {
                return false;
            }
        }));
    }

    public GetDaysToDue(): Observable<string> {
        return this.http.get(Urls.DUE_DATE_DAYS_TO_DUE, { responseType: 'text' }).pipe(map(daysToDue => {
            return daysToDue;
        }));
    }
}
