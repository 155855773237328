
import { animate, group, state, style, transition, trigger } from "@angular/animations";
import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import fileSaver from "file-saver";
import { Observable, Subject, firstValueFrom } from "rxjs";
import { filter, first, takeUntil } from "rxjs/operators";
import { BaseComponent } from "src/app/base-component";
import { ConnectionData } from "src/app/core/connections/models/connection-data.model";
import { LocaleService } from "src/app/core/localisation/services/locale/locale.service";
import { MessageService } from "src/app/core/messages/message.service";
import { UserInformation } from "src/app/core/security/models/userinformation/userinformation.model";
import { DueDateService } from "src/app/core/security/shared/services/due-date/due-date.serivce";
import { UserRightsService } from "src/app/core/security/shared/services/user-rights/user-rights.service";
import { UserInformationService } from "src/app/core/security/shared/services/userinformation/userinformation.service";
import { ConditionsService } from "src/app/core/space/services/conditions/conditions.service";
import { EvoRebootService } from "src/app/core/space/services/evoreboot/evoreboot.service";
import clientSettings from "src/app/globals/client-app-settings.json";
import { SessionStorage } from "src/app/globals/session-storage";
import { FileBlob } from "src/app/overview/file/models/file-blob.model";
import { SystemFileService } from "src/app/overview/file/services/system-file.service";
import { VersionManagementService } from "src/app/start/offline/pre-release-home/services/version-management/version-management-service";
import { SurveyClientService } from "src/app/survey/client/services/survey-client.service";
import { SurveyModel } from "src/app/survey/shared/models/survey.model";
import { NavigationItem } from "../navigation-core/models/navigation-item.model";
import { NavigationService } from "../navigation-core/services/navigation.service";
import { SearchItem } from "./../../shared/toolbox/search-bar/search-bar.component";
import { LanguageSelectDialogComponent } from "./language-select-dialog/language-select-dialog.component";
import ConnectionStatusModel from "./models/connection-status.model";
import { ConnectionStatusHubService } from "./services/connection-status.hub.service";
import { DocumentUrlService } from "./services/document-url.service";

interface InlineSvgIcons {
    setupIcon: SafeHtml;
    fileIcon: SafeHtml;
    infoIcon: SafeHtml;
    reportProblemIcon: SafeHtml;
}


@Component({
    selector: "hic-navbar",
    templateUrl: "./navigationbar.component.html",
    styleUrls: ["./navigationbar.component.scss"],
    animations: [
        trigger("slideUpDown", [
            state("down", style({
                "max-height": "500px", visibility: "visible"
            })),
            state("up", style({
                "max-height": "0px", visibility: "hidden"
            })),
            transition("down => up", [group([
                animate("200ms ease-in-out", style({
                    "max-height": "0px"
                })),
                animate("300ms ease-in-out", style({
                    visibility: "hidden"
                }))
            ]
            )]),
            transition("up => down", [group([
                animate("1ms ease-in-out", style({
                    visibility: "visible"
                })),
                animate("200ms ease-in-out", style({
                    "max-height": "500px",
                }))
            ]
            )])
        ])
    ],
})
export class NavigationBarComponent extends BaseComponent implements OnInit, OnDestroy {
    @Input() public offlineVersion = false;

    private componentDestroyed$ = new Subject<boolean>();
    public menus$: Observable<NavigationItem[][]>;

    public isLoggedIn: boolean;
    public releaseName = "";
    public releaseNotes = "";
    public areReleaseNotesVisible = false;

    public dueWarning = false;
    public daysToDue = "a few";

    public runningDevSite = false;
    public runningReviewSite = false;
    public runningEvoFunctionalSite = false;

    public currentConnection: ConnectionData;

    public searchItems: SearchItem[] = [];

    public userMenuVisible = false;
    public showDecryptDialog = false;

    public currentRoute = "";
    public userInformation: UserInformation;
    public hasUserAdminAccess: boolean;
    public hasUserAdminNewAccess: boolean;
    public hasCraneDataAccess: boolean;
    public hasCraneEcuAndSerialAccess: boolean;
    public hasHiTestAccess: boolean;
    public hasCeCertAccess: boolean;
    public hasSuperHiTestAccess: boolean;
    public hasSuperFeatureTypeAccess: boolean;
    public hasHiSetAdminAccess: boolean;
    public hasHiSetReportAccess: boolean;
    public hasDownloadAccess: boolean;
    public hasFlashFilesAccess: boolean;
    public hasGidListFilesAccess: boolean;
    // TODO: Implement user group access rights for VSL Report
    // https://jira.shared.tds.cargotec.com/browse/HIAA-5902
    public hasVslReportAccess = true;
    public hasPlcCreatorAccess: boolean;
    public hasPlcAdminAccess: boolean;
    public hasElevateUserRightsAccess: boolean;
    public hasDecryptAccess: boolean;
    public hasSystemFilesAccess: boolean;
    public userMenuIcons: InlineSvgIcons;
    public devEnvironment: boolean;
    public showMegaMenu = false;

    public connectionStatus: ConnectionStatusModel | null = null;
    public rebooting: boolean;
    public showRebootButton: boolean;
    public hideRebootRequiredButton$: Observable<boolean>;

    public allowedFileEndingsDecrypt = ".aes";

    public rebootIconName = "reboot";
    public surveyIconName = "survey";
    public survey: SurveyModel;

    constructor(
        public userInformationService: UserInformationService,
        conditionsService: ConditionsService,
        private router: Router,
        @Inject(SessionStorage) private sessionStorage: unknown,
        public changeDetector: ChangeDetectorRef,
        public dueDateService: DueDateService,
        private userRightsService: UserRightsService,
        private httpClient: HttpClient,
        private sanitizer: DomSanitizer,
        private versionManagementService: VersionManagementService,
        private localeService: LocaleService,
        private navigationService: NavigationService,
        private connectionStatusHubService: ConnectionStatusHubService,
        private evoRebootService: EvoRebootService,
        private messageService: MessageService,
        private systemFileService: SystemFileService,
        private documentUrlService: DocumentUrlService,
        private matDialog: MatDialog,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private userMessageServce: SurveyClientService
    ) {
        super(conditionsService, changeDetector);
        BaseComponent.offlineMode = this.offlineVersion;

        this.matIconRegistry.addSvgIcon(
            this.rebootIconName,
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/symbols/ic-reboot.svg")
        );

        this.matIconRegistry.addSvgIcon(
            this.surveyIconName,
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/symbols/survey.svg")
        );

        this.navigationService.setupFinished
        this.menus$ = navigationService.activeMenu$;

        navigationService.activeMenu$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async _menus => {
            const flattenedItems = await navigationService.getFlattenedMenuItemsAsync(false);
            this.searchItems = [];
            for (const item of flattenedItems) {
                if (!item.isHeader && !item.children?.length) {
                    this.addItemToSearch(item);
                }
            }
        });

        router.events
            .pipe(takeUntil(this.componentDestroyed$), filter(event => event instanceof NavigationEnd))
            .subscribe(_event => {
                const { fragment } = router.parseUrl(router.url);
                if (fragment) {
                    setTimeout(() => {
                        const elem = document.querySelector(`#${fragment}`);
                        if (elem) {
                            elem.scrollIntoView();
                        }
                    }, 0);
                } else {
                    window.scrollTo({ top: 0 });
                }
            });

        this.router.events.pipe(takeUntil(this.componentDestroyed$)).subscribe(path => {
            if (path instanceof NavigationEnd) {
                if (path.url !== this.currentRoute) {
                    this.currentRoute = path.url;
                }
            }
        });

        // Set empty menu
        const selectedLocale = this.getSelectedLocale();
        this.localeService.selectedLocale = selectedLocale.code;


        this.hideRebootRequiredButton$ = connectionStatusHubService.hideRebootRequiredButton.asObservable();
        this.userMessageServce.availableSurvey$.pipe(takeUntil(this.componentDestroyed$)).subscribe((survey) => {
            this.survey = survey;
        });
    }

    public openSurvey(survey: SurveyModel): void {
        this.userMessageServce.openSurvey(survey);
    }

    public getSelectedLocale(): { code: string; name: string } {
        // get thew two first characters from the pathname in the url
        const localeFromUrl = window.location.pathname.split("/")[1];
        if (!localeFromUrl) {
            return LocaleService.locales[0];
        }

        // if the first two characters does not exist in any getLocale().code, return en
        for (const locale of LocaleService.locales) {
            if (locale.code === localeFromUrl) {
                return locale;
            }
        }
        return LocaleService.locales[0];
    }

    public get routeName(): string {
        // -> Splits string at capital letters. E.g. "VariablesPage" becomes "Variables Page";
        const str = this.router.url.replace("/", "").split(/(?=[A-Z])/).join(" ");
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    public showUserMenu(): void {
        if (!this.userMenuVisible) {
            setTimeout(() => this.userMenuVisible = true, 0);
        }
    }

    public hideUserMenu(): void {
        if (this.userMenuVisible) {
            setTimeout(() => this.userMenuVisible = false, 0);
        }
    }

    public powerOff(): void {
        if (this.offlineVersion) {
            window.location.href = "/api/Shutdown/shutdown";
        }
    }

    public logOut(): void {
        this.userInformation = undefined;
        this.isLoggedIn = false;
        this.hideUserMenu();
        this.connectionId = "";
        this.router.navigateByUrl("/logout");
    }

    public async logIn(): Promise<void> {
        this.hideUserMenu();
        this.router.navigateByUrl("/");
    }

    public async redirectHome(): Promise<void> {
        await this.router.navigateByUrl("/start");
    }

    public async ngOnInit(): Promise<void> {
        if (clientSettings.DevSite === "1") {
            this.runningDevSite = true;
        }

        if (clientSettings.DevSite === "2") {
            this.runningReviewSite = true;
        }

        if (clientSettings.DevSite === "3") {
            this.runningEvoFunctionalSite = true;
        }

        BaseComponent.offlineMode = this.offlineVersion;

        this.conditionsService.connectionChanged$.pipe(takeUntil(this.componentDestroyed$)).subscribe((event) => {
            this.currentConnection = event;
            console.log("Connection changed --- ", this.currentConnection.isConnected);
            this.checkRebootStatus();
        });

        // Get data regarding due-date
        this.dueDateService.daysToDueReady.pipe(takeUntil(this.componentDestroyed$)).subscribe((_event) => {
            this.daysToDue = this.dueDateService.daysToDue;
        });

        this.dueDateService.dueSoonReady.pipe(takeUntil(this.componentDestroyed$)).subscribe((_event) => {
            this.dueWarning = this.dueDateService.dueSoon;
        });

        this.userInformationService.onUserInformation$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async userInformation => {
            this.userInformation = userInformation;
            this.isLoggedIn = !!userInformation;
            if (this.offlineVersion) {
                this.dueDateService.getDaysToDue();
                this.dueDateService.getDueSoon();
            }

            this.hasUserAdminAccess = await this.hasUserAdminAccessAsync();
            this.hasUserAdminNewAccess = await this.hasUserAdminNewAccessAsync();
            this.hasCraneDataAccess = await this.hasCraneDataAccessAsync();
            this.hasCraneEcuAndSerialAccess = await this.hasCraneSerialAndEcuAccessAsync();
            this.hasDownloadAccess = await this.hasDownloadAccessAsync();
            this.hasHiTestAccess = await this.hasHiTestAccessAsync();
            this.hasCeCertAccess = await this.hasCeCertAccessAsync();
            this.hasSuperHiTestAccess = await this.hasSuperHiTestAccessAsync();
            this.hasSuperFeatureTypeAccess = await this.hasSuperFeatureTypeAccessAsync();
            this.hasHiSetAdminAccess = await this.hasParameterAccessAsync();
            this.hasHiSetReportAccess = await this.hasHiSetReportAccessAsync();
            this.hasFlashFilesAccess = await this.hasFlashFilesAccessAsync();
            this.hasGidListFilesAccess = await this.hasGidListFilesAccessAsync();
            this.hasPlcCreatorAccess = await this.hasPlcCreatorAccessAsync();
            this.hasPlcAdminAccess = await this.hasPlcAdminAccessAsync();
            this.hasElevateUserRightsAccess = await this.hasTemporaryAdminAccessAsync();
            this.hasDecryptAccess = await this.hasDecryptAccessAsync();
            this.hasSystemFilesAccess = await this.hasSystemFilesAccessAsync();

            this.versionManagementService.getReleaseNameAsync().then((releaseName) => {
                this.releaseName = releaseName ? "v" + releaseName : "";
            });

            await this.connectionStatusHubService.start();
        });

        this.userMenuIcons = {
            fileIcon: await this.getSvgAssetContent("file.svg"),
            setupIcon: await this.getSvgAssetContent("setup.svg"),
            infoIcon: await this.getSvgAssetContent("lightbulb-icon.svg"),
            reportProblemIcon: await this.getSvgAssetContent("report-problem.svg")
        };

        await this.userInformationService.getUserInformation();

        this.connectionStatusHubService.connectionStatus$.pipe(takeUntil(this.componentDestroyed$))
            .subscribe(connectionStatus => {
                this.connectionStatus = connectionStatus;
                this.checkRebootStatus();
            });
    }

    private checkRebootStatus(): void {
        if (this.connectionStatus?.id === this.currentConnection?.id
            && this.currentConnection?.id
            && this.connectionStatus?.id
        ) {
            this.showRebootButton = this.connectionStatus.rebootRequired;
        } else {
            this.showRebootButton = false;
        }
    }

    public ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    public openTechnicalDocument(): void {
        this.documentUrlService.getTechnicalDocumentUrl().then(result => {
            window.open(result.url, "_blank", "noopener").focus();
        });
    }

    public toggleShowMegaMenu(show: boolean): void {
        if (show) {
            if (!this.showMegaMenu) {
                setTimeout(() => this.showMegaMenu = show, 0);
            }
        } else {
            if (this.showMegaMenu) {
                setTimeout(() => this.showMegaMenu = show, 0);
            }
        }
    }

    public navigateToUserAdmin(): void {
        if (this.hasUserAdminNewAccess) {
            this.router.navigateByUrl("/admin/users-new");
            this.userMenuVisible = false;
        }
    }

    public navigateToUserAdminOld(): void {
        if (this.hasUserAdminAccess) {
            this.router.navigateByUrl("/admin/users");
            this.userMenuVisible = false;
        }
    }

    public navigateToCraneData(): void {
        if (this.hasCraneDataAccess) {
            this.router.navigateByUrl("/admin/cranedata");
            this.userMenuVisible = false;
        }
    }

    public navigateToCraneEcuAndSerialAdmin(): void {
        if (this.hasCraneEcuAndSerialAccess) {
            this.router.navigateByUrl("/admin/crane-ecu-and-serial");
            this.userMenuVisible = false;
        }
    }

    public navigateToCraneFeatureAdmin(): void {
        if (this.hasSuperFeatureTypeAccess) {
            this.router.navigateByUrl("/admin/featuretype");
            this.userMenuVisible = false;
        }
    }

    public navigateToFlashFiles(): void {
        if (this.hasFlashFilesAccess) {
            this.router.navigateByUrl("/admin/flash-files");
            this.userMenuVisible = false;
        }
    }

    public navigateToGidListFiles(): void {
        if (this.hasGidListFilesAccess) {
            this.router.navigateByUrl("/admin/gidlist-files");
            this.userMenuVisible = false;
        }
    }

    public navigateToInteractiveDiagrams(): void {
        this.router.navigateByUrl("/interactive-diagrams");
        this.userMenuVisible = false;
    }

    public navigateToVslReport(): void {
        if (this.hasVslReportAccess) {
            this.router.navigateByUrl("/safety/stability/vsl-report");
            this.userMenuVisible = false;
        }
    }

    public navigateToDownloadOffline(): void {
        if (this.hasDownloadAccess) {
            this.router.navigateByUrl("/download-offline");
            this.userMenuVisible = false;
        }
    }

    public navigateToServiceTools(): void {
        this.router.navigateByUrl("/overview/file/service-tools");
        this.userMenuVisible = false;
    }

    public openIssueReportLink(): void {
        window.open("https://forms.gle/oczPepfaY1Htz2Tx5");
    }

    public navigateToHiTest(): void {
        if (this.hasHiTestAccess) {
            this.router.navigateByUrl("/hitest/admin/module-config");
            this.userMenuVisible = false;
        }
    }

    public navigateToCeCert(): void {
        if (this.hasCeCertAccess) {
            this.router.navigateByUrl("/ce-certs/admin");
            this.userMenuVisible = false;
        }
    }

    public navigateToSuperHiTest(): void {
        if (this.hasSuperHiTestAccess) {
            this.router.navigateByUrl("/hitest/super-ad");
            this.userMenuVisible = false;
        }
    }

    public navigateToHiSetAdmin(): void {
        if (this.hasHiSetAdminAccess) {
            this.router.navigateByUrl("/hiset/code-group");
            this.userMenuVisible = false;
        }
    }

    public navigateToHiSetReport(): void {
        if (this.hasHiSetReportAccess) {
            this.router.navigateByUrl("/hiset-report");
            this.userMenuVisible = false;
        }
    }

    public navigateToPlcCreator(): void {
        if (this.hasPlcCreatorAccess) {
            this.router.navigateByUrl("/admin/plc-creator");
            this.userMenuVisible = false;
        }
    }

    public navigateToPlcAdmin(): void {
        if (this.hasPlcAdminAccess) {
            this.router.navigateByUrl("/admin/plc-admin");
            this.userMenuVisible = false;
        }
    }

    public async downloadErrorDescriptionDocument(): Promise<void> {
        const ref = this.matDialog.open(LanguageSelectDialogComponent, {
            width: "340px",
            data: this.localeService.selectedLocale
        });

        const language = await firstValueFrom(ref.afterClosed());
        if (language) {
            const result = await this.documentUrlService.getErrorDescriptionDocumentUrl(language);
            window.open(result.url, "_blank", "noopener").focus();
        }

        this.userMenuVisible = false;
    }

    public navigateToElevateUserRights(): void {
        if (this.hasElevateUserRightsAccess) {
            this.router.navigateByUrl("/admin/elevate-user-rights");
            this.userMenuVisible = false;
        }
    }

    public navigateToSystemFiles(): void {
        if (this.hasSystemFilesAccess) {
            this.router.navigateByUrl("/admin/system-files");
            this.userMenuVisible = false;
        }
    }

    public chooseEncryptedSystemFile(): void {
        if (this.hasDecryptAccess) {
            this.showDecryptDialog = true;
        }
    }

    public async showReleaseNotes(): Promise<void> {
        this.areReleaseNotesVisible = true;
        this.releaseNotes = await this.versionManagementService.getReleaseNotesAsync();
    }

    public hideReleaseNotes(): void {
        this.areReleaseNotesVisible = false;
        this.releaseNotes = "";
    }

    private addItemToSearch(_item: NavigationItem): void {
        // if (item.route != null) {
        //     // Add title
        //     this.addToSearchItems(
        //         item.title,
        //         item.title,
        //         item.route,
        //     );

        //     // Add keys
        //     if (item.searchKeys !== undefined) {
        //         for (const searchKey of item.searchKeys) {
        //             this.addToSearchItems(
        //                 item.title,
        //                 searchKey,
        //                 item.route,
        //             );
        //         }
        //     }
        // }
    }


    public changeUrl(langCode: string): void {

        const languageCode = langCode === "en-GB" ?
            "" : langCode.toLocaleLowerCase();
        const paths = window.location.pathname.split("/");
        paths.shift();

        if (paths[0].length === 2) {
            paths[0] = languageCode;
        } else {
            paths.unshift(languageCode);
        }

        const newLocation = ("/" + paths.join("/")).replace("//", "/");

        window.location.href = newLocation;
    }

    public async reboot(): Promise<void> {
        this.rebooting = true;
        const status = await this.evoRebootService.completeReboot();
        if (status) {
            this.messageService.sendMessage("Reboot completed");
        } else {
            this.messageService.sendWarning("Reboot failed");
        }
        this.rebooting = false;
    }

    public async decryptSystemFile(files: FileList): Promise<void> {
        this.hideDecryptDialog();

        const fileToDecrypt = files[0];

        if (this.hasDecryptAccess &&
            fileToDecrypt.name.toLowerCase().endsWith(".aes")) {

            const fileBlob = await this.systemFileService.decryptSystemFileAsync(fileToDecrypt);
            this.downloadFile(fileBlob);
        }
    }

    public async showLanguageSelection(): Promise<void> {
        const ref = this.matDialog.open(LanguageSelectDialogComponent, {
            width: "340px",
            data: this.localeService.selectedLocale

        });

        const result = await firstValueFrom(ref.afterClosed());
        if (result) {
            this.localeService.selectedLocale = result;
            this.changeUrl(result);
        }

    }

    public hideDecryptDialog(): void {
        this.showDecryptDialog = false;
    }

    private downloadFile(fileBlob: FileBlob): void {
        if (fileBlob !== undefined) {
            const url = window.URL.createObjectURL(fileBlob.blob);
            fileSaver.saveAs(url, fileBlob.fileName);
        }
    }

    private addToSearchItems(title: string, searchKey: string, route: string): void {
        this.searchItems.push({
            title,
            searchKey,
            route,
        });
    }

    private async getSvgAssetContent(svgName: string): Promise<SafeHtml> {
        const data = await this.httpClient.get("assets/images/" + svgName, { responseType: "text" }).pipe(first()).toPromise();
        return this.sanitizer.bypassSecurityTrustHtml(data);
    }

    private async hasUserAdminAccessAsync(): Promise<boolean> {
        return !this.offlineMode && await this.userRightsService.isRouteAllowed("/admin/users");
    }

    private async hasUserAdminNewAccessAsync(): Promise<boolean> {
        return !this.offlineMode && await this.userRightsService.isRouteAllowed("/admin/users-new");
    }

    private async hasCraneDataAccessAsync(): Promise<boolean> {
        return !this.offlineMode && await this.userRightsService.isRouteAllowed("/admin/cranedata");
    }

    private async hasCraneSerialAndEcuAccessAsync(): Promise<boolean> {
        return !this.offlineMode && await this.userRightsService.isRouteAllowed("/admin/crane-ecu-and-serial");
    }

    private async hasHiTestAccessAsync(): Promise<boolean> {
        return !this.offlineMode && await this.userRightsService.isRouteAllowed("/hitest/admin/module-config");
    }

    private async hasCeCertAccessAsync(): Promise<boolean> {
        return !this.offlineMode && await this.userRightsService.isRouteAllowed("/ce-certs/admin");
    }

    private async hasSuperHiTestAccessAsync(): Promise<boolean> {
        return !this.offlineMode && await this.userRightsService.isRouteAllowed("/hitest/super-ad");
    }

    private async hasSuperFeatureTypeAccessAsync(): Promise<boolean> {
        return !this.offlineMode && await this.userRightsService.isRouteAllowed("/admin/featuretype");
    }

    private async hasParameterAccessAsync(): Promise<boolean> {
        return !this.offlineMode && await this.userRightsService.isRouteAllowed("/hiset");
    }

    private async hasHiSetReportAccessAsync(): Promise<boolean> {
        return !this.offlineMode && await this.userRightsService.isRouteAllowed("/hiset-report");
    }

    private async hasFlashFilesAccessAsync(): Promise<boolean> {
        return !this.offlineMode && await this.userRightsService.isRouteAllowed("/admin/flash-files");
    }

    private async hasGidListFilesAccessAsync(): Promise<boolean> {
        return !this.offlineMode && await this.userRightsService.isRouteAllowed("/admin/gidlist-files");
    }

    private async hasDownloadAccessAsync(): Promise<boolean> {
        return !this.offlineMode && await this.userRightsService.isRouteAllowed("/download-offline");
    }

    private async hasPlcCreatorAccessAsync(): Promise<boolean> {
        return this.offlineMode && await this.userRightsService.isRouteAllowed("/admin/plc-creator");
    }

    private async hasPlcAdminAccessAsync(): Promise<boolean> {
        return !this.offlineMode && await this.userRightsService.isRouteAllowed("/admin/plc-admin");
    }

    private async hasTemporaryAdminAccessAsync(): Promise<boolean> {
        return !this.offlineMode && await this.userRightsService.isRouteAllowed("/admin/elevate-user-rights");
    }

    private async hasDecryptAccessAsync(): Promise<boolean> {
        return await this.userRightsService.isComponentAllowed("DecryptSystemFile");
    }

    private async hasSystemFilesAccessAsync(): Promise<boolean> {
        return await this.userRightsService.isRouteAllowed("/admin/system-files");
    }
}
