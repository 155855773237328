import { Inject, Injectable, OnDestroy } from "@angular/core";
import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { Observable, Subject } from "rxjs";
import { BaudRateIdentifier } from "src/app/core/connections/serial/models/baud-rate.model";
import { CommunicationProtocol } from "src/app/core/connections/enums/communication-protocol.enum";
import { ControlSystemGeneration } from "src/app/core/connections/enums/control-system-generation.enum";
import { MessageService } from "src/app/core/messages/message.service";
import { SIGNALR_TOKEN } from "src/app/signal-r.provider";
import { AvailableConnection } from "../models/available-connection.model";
import { HubUrls } from "src/app/globals/urls";

@Injectable({
    providedIn: "any"
})
export class AvailableConnectionHubService implements OnDestroy {
    public static readonly receiveConnectionsTopic: string = "ReceiveAvailableConnections";
    public static readonly receiveDisconnectedConnectionTopic: string = "ReceiveDisconnectedConnection";

    private readonly invocables = {
        subscribe: "Subscribe",
        connect: "Connect",
        disconnect: "Disconnect"
    };

    public hubStarted: Promise<void>;

    private hubConnection: HubConnection;

    private availableConnectionsSubject = new Subject<AvailableConnection[]>();
    private disconnectedConnectionSubject = new Subject<string>();
    public availableConnections$: Observable<AvailableConnection[]> = this.availableConnectionsSubject.asObservable();
    public disconnectedConnection$: Observable<string> = this.disconnectedConnectionSubject.asObservable();

    constructor(
        @Inject(SIGNALR_TOKEN)
        private signalR: any,
        private messageService: MessageService
    ) {
        const builder = this.signalR.HubConnectionBuilder as HubConnectionBuilder;
        this.hubConnection = builder.withUrl(HubUrls.availableConnections).build();
    }

    public async start() {
        try {
            if (this.hubConnection.state !== HubConnectionState.Disconnected) {
                await this.hubStarted;
                return;
            }

            this.initAvailableConnections();

            this.hubStarted = this.hubConnection.start();
            await this.hubStarted;

        } catch (error: unknown) {
            const errorMessage = "Connecting to Available connections hub failed.";
            console.error(errorMessage, error);
            this.messageService.sendAlert(errorMessage);
            throw error;
        }
    }

    /**
     * Connect to SPACEevo/X4
     * @param id unique GUID from the available connections object
     */
    public async startConnection(id: string, baudRate: BaudRateIdentifier): Promise<string> {
       const connectionId: string  = await this.hubConnection.invoke(this.invocables.connect, id, baudRate);
       return connectionId;
    }

    /**
     * Disconnect from SPACEevo/X4
     * @param id unique GUID from the available connections object
     */
    public async endConnection(id: string): Promise<void> {
        await this.hubConnection.invoke(this.invocables.disconnect, id);
    }

    private initAvailableConnections(): void {
        this.hubConnection.on(AvailableConnectionHubService.receiveConnectionsTopic, (connections: AvailableConnection[]) => {
            this.availableConnectionsSubject.next(connections);
        });
        this.hubConnection.on(AvailableConnectionHubService.receiveDisconnectedConnectionTopic, (connectionId: string) => {
            this.disconnectedConnectionSubject.next(connectionId);
        });
    }

    public async stop(): Promise<void> {
        this.hubConnection.off(AvailableConnectionHubService.receiveConnectionsTopic);
        await this.hubConnection.stop();

        this.availableConnectionsSubject.complete();
    }

    public async ngOnDestroy(): Promise<void> {
        await this.stop();
    }
}
