import { CdkMenuModule } from "@angular/cdk/menu";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { MarkdownModule } from "ngx-markdown";
import { CoreModule } from "../core/core.module";
import { ToolboxModule } from "../shared/toolbox/toolbox.module";
import { MegaMenuComponent } from "./mega-menu/components/mega-menu.component";
import { ConnectionStateComponent } from "./navbar/connection-state/connection-state.component";
import { NavigationSearchComponent } from "./navbar/navigation-search/navigation-search.component";
import { NavigationBarComponent } from "./navbar/navigationbar.component";

@NgModule({
    declarations: [
        NavigationBarComponent,
        MegaMenuComponent,
        ConnectionStateComponent,
        NavigationSearchComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        CoreModule,
        ToolboxModule,
        BrowserModule,
        BrowserAnimationsModule,
        MarkdownModule.forChild(),
        CdkMenuModule,
        MatTooltipModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatInputModule,
        MatSortModule,
        ReactiveFormsModule,
        MatSelectModule
    ],
    providers: [],
    exports: [NavigationBarComponent]
})
export class NavigationModule {}
