import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BaseComponent } from "src/app/base-component";
import { ControlSystemGeneration } from "src/app/core/connections/enums/control-system-generation.enum";
import { Variable, WatchedVariable } from "src/app/core/space/models/signals/variable.model";
import { IWatchable } from "src/app/core/space/models/space-value/space-value.interface";
import { ConditionsService } from "src/app/core/space/services/conditions/conditions.service";
import { VariableDataDto, VariableDto } from "src/app/overview/diagnostics/variables/models/variable.dto";
import { VariableService } from "src/app/overview/diagnostics/variables/services/variable.service";
import { SearchItem } from "./../../../../../shared/toolbox/search-bar/search-bar.component";
import { FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";

@Component({
    selector: "hic-variables-mini-display",
    templateUrl: "./variables-mini-display.component.html",
    styleUrls: ["./variables-mini-display.component.scss"],
    providers: [VariableService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariablesMiniDisplayComponent extends BaseComponent implements OnInit, OnDestroy {

    private componentDestroyed$ = new Subject<boolean>();

    private readonly viewName = "variablesMiniDisplay";

    public filteredList: SearchItem[] = [];
    public searchFieldInput: string;

    private areVariablesReady = false;
    private expandedVariables: string[] = [];

    public filterOptionControl: FormControl = new FormControl();

    public currentImage = "edit.svg";
    public searchItems: SearchItem[] = [];
    public variables: Variable[] = [];
    public watchedVariables: WatchedVariable[] = [];
    private isEvoSystem: boolean;
    private variableDto: VariableDto[] = [];
    public searchPlaceholder: string = $localize `:@@search-variable:search variable`;

    constructor(
        private variableService: VariableService,
        public conditionsService: ConditionsService,
                changeDetector: ChangeDetectorRef
    ) {
        super(conditionsService, changeDetector);

        this.isEvoSystem = this.conditionsService.controlSystemGeneration === ControlSystemGeneration.Evo;
    }

    async ngOnInit(): Promise<void> {
        this.variableService.onAllVariables$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async (variables) => {
            if (variables) {
                this.variableDto = variables;
                const variablesWithUnit: Variable[] = [];
                    variables.forEach(v => {
                        const variable = Object.assign(
                            {},
                            new Variable(
                                v.id,
                                this.createName(v),
                                v.value,
                                v.min,
                                v.max,
                                v.timestamp,
                                v.defaultValue,
                                v.unit,
                                v.resolution,
                                v.notAvailable));
                        variablesWithUnit.push(variable);
                    });

                this.variables = variablesWithUnit;
                this.areVariablesReady = true;
                await this.initWatchersFromLocalStorage(this.viewName);

                this.buildSearchList(this.variables);

                this.changeDetector.markForCheck();
                this.filteredList = this.searchItems;
            }
        });

        this.variableService.onVariableData$
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((variable) => this.onVariableDataReceived(variable));

        await this.variableService.init();
        await this.variableService.getAllVariables();
        this.changeDetector.markForCheck();
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    public getValue(variable: Variable): number[] {
        return VariableDto.parseValueNumericArray(variable.value);
    }

    public trackByIndex(index: number): number {
        return index;
    }
    public onOptionselected(event: MatAutocompleteSelectedEvent): void {
        this.onSearchItemSelected(event.option.value.searchKey);
    }

    public onSearchItemSelected(searchKey: string): void {
        let variable: Variable;
        if (this.isEvoSystem) {
            const id = this.parseIdFromEvoName(searchKey);
            variable = this.variables.find((v) => v.id === id);
        } else {
            variable = this.variables.find((v) => v.name.includes(searchKey));
        }
        if (variable) {
            this.startWatchingVariable(variable); 
        }
        this.searchFieldInput = "";
        this.filterOptionControl.setValue("")
    }

    public async removeWatcher(variable: WatchedVariable): Promise<void> {
        this.searchItems.push({ title: variable.name, route: variable.name, searchKey: variable.name });
        this.sortSearchList();

        variable.deleteWatcher(this.viewName);
        this.watchedVariables.splice(this.watchedVariables.findIndex((v) => v.name === variable.name), 1);
        await this.variableService.unsubscribe(variable);
        this.storeWatchersInLocalStorage(this.viewName, this.watchedVariables);

        // Remove from expanded list
        if (this.expandedVariables.some((x) => x === variable.name)) {
            const i = this.expandedVariables.findIndex((d) => d === variable.name); // find index in your array
            this.expandedVariables.splice(i, 1); // remove element from array
        }
    }

    public onClickedRow(watched: WatchedVariable): void {
        if (this.expandedVariables.some((x) => x === watched.name)) {
            const i = this.expandedVariables.findIndex((d) => d === watched.name); // find index in your array
            this.expandedVariables.splice(i, 1); // remove element from array
        } else {
            this.expandedVariables.push(watched.name);
        }
    }

    public getRows(watched: WatchedVariable): number[] {
        const rows: number[] = [];
        rows.push(0);

        if (watched.valueNumericArray.length <= 3) {
            return rows;
        }

        // Check if value is expanded
        if (this.expandedVariables.some((x) => x === watched.name)) {
            const r: number = Math.ceil(watched.valueNumericArray.length / 3);
            let i = 1;
            while (i < r) {
                rows.push(i);
                i++;
            }
            return rows;
        } else {
            return rows;
        }
    }

    public getRowToShow(watched: WatchedVariable, row: number): number[] {
        const result: number[] = [];
        if (row * 3 < watched.valueNumericArray.length) {
            let i: number = row * 3;
            while (i < (row + 1) * 3) {
                if (i < watched.valueNumericArray.length) {
                    result.push(watched.valueNumericArray[i]);
                } else if (row > 0) {
                    result.push(null);
                }
                i++;
            }
        }
        return result;
    }
    
    public searchInputChange(): void {
        
        this.filteredList = this.searchItems.filter((v) => {
            const searchWords = this.searchFieldInput.toLowerCase().split(" ");
            return searchWords.every(word => v.searchKey.toLowerCase().includes(word));
        })
    }

    private onVariableDataReceived(variable: VariableDataDto): void {
        let newValue = "";
        let variableToUpdate: WatchedVariable;
        if (this.isEvoSystem) {
            variableToUpdate = this.watchedVariables.find((v) => v.id === variable.id);
            if (variableToUpdate) {
                newValue = variable.value;
            }
        } else {
            variableToUpdate = this.watchedVariables.find((v) => v.name === variable.name);
            if (variableToUpdate) {
                newValue = variable.value;
            }
        }
        if (variableToUpdate && newValue !== variableToUpdate.value) {
            variableToUpdate.value = newValue;
            if (!this.isEvoSystem) {
                variableToUpdate.valueNumericArray = VariableDto.parseValueNumericArray(newValue);
            }

            this.changeDetector.markForCheck();
        }
    }

    private sortSearchList(): void {
        if (this.isEvoSystem) {
            this.searchItems.sort((a, b) => this.parseIdFromEvoName(a.title) < this.parseIdFromEvoName(b.title) ? -1 : 1);
        } else {
            this.searchItems.sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1));
        }
    }

    private async initWatchersFromLocalStorage(viewName: string): Promise<void> {
        if (this.areVariablesReady) {
            const watchers: IWatchable[] = JSON.parse(localStorage.getItem("HiCommand/Watchables/" + viewName));
            if (watchers) {
                const result = this.variables.filter((s) => watchers.some((w) => w.name === s.name));
                const variableNames: string[] = [];
                result.forEach((v) => {
                    const discard = this.isEvoSystem ? variableNames.push(v.id.toString()) : variableNames.push(v.name);

                    this.addToWatchList(v);
                });
                this.variableService.subscribeMany(variableNames);
            }
        }
    }

    private parseIdFromEvoName(evoName: string): number {
        const regExp = /\(([^)]+)\)/;
        const matches = regExp.exec(evoName);
        return parseInt(matches[1], 10);
    }

    private startWatchingVariable(variable: Variable): void {
        this.addToWatchList(variable);
        this.storeWatchersInLocalStorage(this.viewName, this.watchedVariables);
        this.variableService.subscribe(variable);
    }

    private addToWatchList(variable: Variable): void {
        const { id, name, value, min, max, timestamp, defaultValue, unit, resolution, notAvailable } = variable;
        const variableToAdd = new WatchedVariable(id, name, value, min, max, timestamp, defaultValue, unit, resolution, notAvailable);
        variableToAdd.addWatcher(this.viewName);
        this.watchedVariables.unshift(variableToAdd);

        this.searchItems = this.searchItems.filter((s) => !this.watchedVariables.some((v) => v.name === s.searchKey));
    }

    private storeWatchersInLocalStorage(viewName: string, arrayToStore: WatchedVariable[]): void {
        const varsCopy: IWatchable[] = [];
        for (const x of arrayToStore) {
            if (x.watchedBy.length > 0 && x.watchedBy.includes(this.viewName)) {
                varsCopy.push({
                    index: x.index,
                    name: x.name,
                    watchedBy: [viewName],
                    addWatcher: null,
                    deleteWatcher: null,
                });
            }
        }

        localStorage.setItem("HiCommand/Watchables/" + viewName, JSON.stringify(varsCopy, ["name", "watchedBy"], "\t"));
    }

    private buildSearchList(variables: Variable[]): void {
        for (const v of variables) {
            this.searchItems.push({ title: v.name, route: v.name, searchKey: v.name });
            this.searchItems = this.searchItems.filter((s) => !this.watchedVariables.some((w) => w.name === s.searchKey));
        }
        this.sortSearchList();
    }

    private createName(dto: VariableDto): string {
        return this.isEvoSystem ? dto.name + ` (${dto.id})` : dto.name;
    }
}
