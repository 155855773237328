import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { BaseComponent } from "src/app/base-component";
import { CollapsablePanelComponent } from "src/app/shared/toolbox/collapsable-panel/components/collapsable-panel.component";
import { ConditionsService } from "src/app/core/space/services/conditions/conditions.service";
import { TruckTopViewSetup } from "src/app/shared/graphics/top-view/models/truck-top-view-setup.model";

@Component({
    selector: "hic-vsl-mini-display",
    templateUrl: "vsl-mini-display.component.html"
})
export class VslMiniDisplayComponent extends BaseComponent implements OnInit {
    public truckTopViewSetup: TruckTopViewSetup = new TruckTopViewSetup(false, true, true);
    @ViewChild("collapsable", { static: true }) parent: CollapsablePanelComponent;
    constructor(public conditionsService: ConditionsService,
                changeDetector: ChangeDetectorRef) {
            super(conditionsService, changeDetector);
        }

    ngOnInit() {
    }
}
