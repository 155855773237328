import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Urls } from "src/app/globals/urls";
import { DownloadUrl } from "src/app/start/models/download-url";

@Injectable({
  providedIn: "root"
})
export class DownloadOfflineHttpService {
    constructor(private httpClient: HttpClient) { }

    public getDownloadUrl(): Observable<DownloadUrl> {
        return this.httpClient.get<DownloadUrl>(Urls.DOWNLOAD_OFFLINE);
    }
}
