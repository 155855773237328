import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { map, Observable, Subject, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base-component";
import { KvaserBitRate, KvaserChannelBitRateNames } from "src/app/core/connections/can/models/kvaser-bitrate.model";
import { CommunicationProtocol } from "src/app/core/connections/enums/communication-protocol.enum";
import { ControlSystemGeneration } from "src/app/core/connections/enums/control-system-generation.enum";
import { BaudRate, BaudRateIdentifier } from "src/app/core/connections/serial/models/baud-rate.model";
import { ConnectionMessageService } from "src/app/core/exception-handling/services/connection-message.service";
import { MessageService } from "src/app/core/messages/message.service";
import { UserRightsService } from "src/app/core/security/shared/services/user-rights/user-rights.service";
import { ConditionsService } from "src/app/core/space/services/conditions/conditions.service";
import { InfoDisplayService } from "src/app/side-menu/info-display.service";
import { AvailableConnection } from "../../models/available-connection.model";
import { ConnectionState } from "../../models/connection-state-enum";
import { AvailableConnectionHubService } from "../../services/available-connection-hub.service";
import { SnapshotService } from "src/app/core/snapshot/snapshot-service";

@Component({
    selector: "hic-available-connections",
    templateUrl: "./available-connections.component.html",
    styleUrls: ["./available-connections.component.scss"],
    providers: [AvailableConnectionHubService]
})
export class AvailableConnectionsComponent extends BaseComponent implements OnInit, OnDestroy {
    @Output() connectionsEmpty = new EventEmitter<boolean>();

    public connectionState = ConnectionState;
    public communicationProtocols = CommunicationProtocol;
    public systemGenerations = ControlSystemGeneration;
    public availableBaudrates = BaudRate.getBaudRateList();
    public selectedBaudRate = BaudRateIdentifier.baudrate_57600;
    public availableBitRates = KvaserBitRate.getKvaserBitRateList();
    public selectedBitRate = KvaserChannelBitRateNames.BITRATE_250K;
    public availableConnections$ = new Observable<AvailableConnection[]>();
    public isConnecting: boolean;
    public hiTestAllowed = false;
    public hiViewAllowed = false;

    private noConnections = true;
    private currentlyDisconnectingConnection: string;
    private componentDestroyed$ = new Subject<boolean>();
    private readonly disconnectMessage: string = $localize `:@@start.active-connection-disconnected-warning:Active connection was disconnected from another view`;

    constructor(
        conditionsService: ConditionsService,
        changeDetector: ChangeDetectorRef,
        private userRightsService: UserRightsService,
        private messageService: MessageService,
        private router: Router,
        private availableConnectionHubService: AvailableConnectionHubService,
        private infoDisplayService: InfoDisplayService,
        private connectionMessageService: ConnectionMessageService,
        private snapshotService: SnapshotService
    ) {
        super(conditionsService, changeDetector);
        infoDisplayService.hide();

        this.availableConnectionHubService.availableConnections$.pipe(takeUntil(this.componentDestroyed$)).subscribe(connections => {
            if (!connections.some(x => x.connectionId === this.conditionsService.connectionId)) {
                this.clearCurrentConnection();
            }

            if (this.noConnections !== connections.length < 1) {
                this.noConnections = connections.length < 1;
                this.connectionsEmpty.emit(this.noConnections);
            }
        });
        this.availableConnectionHubService.disconnectedConnection$.pipe(takeUntil(this.componentDestroyed$)).subscribe(connectionId => {
            if (!this.currentlyDisconnectingConnection && this.conditionsService.connectionId === connectionId) {
                this.clearCurrentConnection();
                this.messageService.sendWarning(this.disconnectMessage);
            }
        });
    }

    public async ngOnInit(): Promise<void> {
        this.hiTestAllowed = await this.userRightsService.isRouteAllowed("/hitest/equipment-selection");
        this.hiViewAllowed = await this.userRightsService.isRouteAllowed("/hiview");

        this.availableConnections$ = this.availableConnectionHubService.availableConnections$.pipe(
            map(x => x.sort((a, b) => a.state - b.state))
        );

        await this.availableConnectionHubService.start();
    }

    public isActive(connectionId: string): boolean {
        return this.conditionsService.connectionId === connectionId;
    }

    public async ngOnDestroy(): Promise<void> {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
        this.infoDisplayService.reset();
        await this.availableConnectionHubService.stop();
    }

    public getStatusText(status: ConnectionState): string {
        return ConnectionState[status];
    }

    public async connectToHiCommand(availableConnection: AvailableConnection): Promise<void> {
        await this.connectAndNavigate(availableConnection, "/overview/summary");
    }

    public async connectToHiView(availableConnection: AvailableConnection): Promise<void> {
        await this.connectAndNavigate(availableConnection, "/hiview");
    }

    public async connectToHiTest(availableConnection: AvailableConnection): Promise<void> {
        await this.connectAndNavigate(availableConnection, "/hitest/equipment-selection");
    }

    private async connectAndNavigate(availableConnection: AvailableConnection, url: string): Promise<void> {
        const previousConnectionId = this.conditionsService.connectionId?.toString();
        const navigateTo = url;
        await this.connect(availableConnection, navigateTo)
            .then(async () => {
                await (this.createX4SnapshotIfConnectionIdIsDifferent(previousConnectionId, availableConnection));
            });
    }


    private async createX4SnapshotIfConnectionIdIsDifferent(
        previousConnectionId: string,
        availableConnection: AvailableConnection): Promise<void> {
            if (availableConnection.communicationProtocol === CommunicationProtocol.Serial
                && availableConnection.controlSystemGeneration === ControlSystemGeneration.X4) {
                    if (this.conditionsService.connectionId === previousConnectionId) {
                        console.warn("same con id");
                        return;
                    }
                    // wait for everything to be read before firing
                    // otherwise, the backend services will not be ready
                    await new Promise(resolve => setTimeout(resolve, 3000));
                    this.snapshotService.createSnapshot();
            }
    }

    public async disconnect(connectionId: string): Promise<void> {
        try {
            this.isConnecting = true;
            this.connectionMessageService.disconnectFromHub();
            this.currentlyDisconnectingConnection = connectionId;
            await this.availableConnectionHubService.endConnection(connectionId);
            if (connectionId === this.conditionsService.connectionId) {
                this.clearCurrentConnection();
            }
        } finally {
            this.isConnecting = false;
            this.currentlyDisconnectingConnection = undefined;
        }
    }

    public connectionUpdated(connectionId: string, controlSystemGeneration: ControlSystemGeneration, protocol: CommunicationProtocol) {
        this.conditionsService.connectionId = connectionId;
        this.conditionsService.controlSystemGeneration = controlSystemGeneration;
        this.conditionsService.communicationProtocol = protocol;
        this.conditionsService.getServerSideConnectionMode();
    }

    public getControlSystemGenerationText(controlSystemGeneration: ControlSystemGeneration): string {
        return ControlSystemGeneration[controlSystemGeneration];
    }

    public getCommunicationProtocolText(communicationProtocol: CommunicationProtocol): string {
        return CommunicationProtocol[communicationProtocol];
    }

    /**
     * Refocuses the select after a selected option has been clicked
     */
    public keepFocus(event: MouseEvent) {
        const element = event.target as HTMLElement;
        const select = element.closest("mat-select") as any;
        select.focused = true;
    }

    public getConnectionName(availableConnection: AvailableConnection): string {
        switch (availableConnection.communicationProtocol) {
            case CommunicationProtocol.MQTT:
                return $localize `:@@start.remote:Remote`;
            case CommunicationProtocol.HiConnect:
                return $localize `:@@start.hiconnect-cloud:HiConnect cloud`;
            case CommunicationProtocol.Sac:
            case CommunicationProtocol.SystemFile:
                return $localize `:@@start.backup-file:Backup file`;
            case CommunicationProtocol.Can:
            case CommunicationProtocol.Serial:
                return $localize `:@@start.cable:Cable`;
            default:
                return $localize `:@@start.undefined:Undefined`;
        }
    }

    public trackByConnection(index: number, connection: AvailableConnection) {
        return connection ? connection.id : undefined;
    }

    public isEnabled(connectionState: ConnectionState): boolean {
        return connectionState === ConnectionState.Available || connectionState === ConnectionState.Connected;
    }

    // TODO: Connect to a CAN module https://jira.shared.tds.cargotec.com/browse/HIAA-5853
    private async connect(availableConnection: AvailableConnection, navigateUrl: string): Promise<void> {
        try {
            if (availableConnection.state === ConnectionState.Available) {
                this.isConnecting = true;
                const currentId = await this.availableConnectionHubService.startConnection(availableConnection.id, this.selectedBaudRate);
                if (!currentId || currentId === "") {
                    throw new Error();
                }
                this.connectionUpdated(currentId, availableConnection.controlSystemGeneration, availableConnection.communicationProtocol);
            } else if (
                availableConnection.state === ConnectionState.Connected &&
                availableConnection.id !== this.conditionsService.connectionId
            ) {
                this.connectionUpdated(
                    availableConnection.id,
                    availableConnection.controlSystemGeneration,
                    availableConnection.communicationProtocol
                );
            }
            this.connectionMessageService.connectToHub();
            this.router.navigateByUrl(navigateUrl);
        } catch (error) {
            this.messageService.sendAlert($localize `:@@start.unable-to-connect:Unable to connect`);
        } finally {
            this.isConnecting = false;
        }
    }

    private clearCurrentConnection() {
        this.connectionUpdated("", ControlSystemGeneration.Undefined, CommunicationProtocol.NotConnected);
    }
}
