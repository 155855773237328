import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Urls } from "src/app/globals/urls";
import { VersionDto } from "../../models/version.dto";


@Injectable({
    providedIn: "root"
})
export class VersionManagementHttpService {

    constructor(private http: HttpClient) { }

    public async checkNewVersionAvailable(): Promise<VersionDto> {
        return await firstValueFrom(this.http.get<VersionDto>(Urls.CHECK_NEW_VERSION_AVAILABLE));
    }

    public async getReleaseName(): Promise<string> {
        return await firstValueFrom(this.http.get(Urls.GET_RELEASE_NAME, { responseType: "text" }));
    }

    public async getReleaseNotes(): Promise<string> {
        return firstValueFrom(this.http.get(Urls.GET_RELEASE_NOTES, { responseType: "text" }));
    }
}
