import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatAccordion, MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { HiTestModule } from "../hitest/hitest.module";
import { VslReportGraphModuleModule } from "../safety/stability/vsl-report/components/vsl-report-graph/vsl-report-graph.module";
import { SpinnerModule } from "../shared/material-spinner/spinner.module";
import { ToolboxModule } from "../shared/toolbox/toolbox.module";
import { GraphicsModule } from "./../shared/graphics/graphics.module";
import { InfoDisplayComponent } from "./components/info-display.component";
import { CriteriasMiniDisplayComponent } from "./components/mini-displays/criterias/components/criterias-mini-display.component";
import { ErrorsMiniDisplayComponent } from "./components/mini-displays/errors/components/errors-mini-display.component";
import { InformationMiniDisplayComponent } from "./components/mini-displays/information/components/information-mini-display.component";
import { VslMiniDisplayComponent } from "./components/mini-displays/top-view/components/vsl-mini-display.component";
import { VariablesMiniDisplayComponent } from "./components/mini-displays/variables/components/variables-mini-display.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatTooltip, MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
    declarations: [
        InfoDisplayComponent,
        ErrorsMiniDisplayComponent,
        VariablesMiniDisplayComponent,
        VslMiniDisplayComponent,
        InformationMiniDisplayComponent,
        CriteriasMiniDisplayComponent,
    ],
    imports: [
        CommonModule,
        GraphicsModule,
        ToolboxModule,
        HiTestModule,
        VslReportGraphModuleModule,
        MatButtonModule,
        MatIconModule,
        SpinnerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatTooltipModule,
        MatAccordion
    ],
    exports: [
        InfoDisplayComponent,
    ],
    providers: [],
})
export class SideMenuModule { }
