import { APP_BASE_HREF } from "@angular/common";
import {Inject, Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class RedirectGuard  {

    constructor(private router: Router, @Inject(APP_BASE_HREF) public baseHref: string) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const onlineClient = environment.onlineClient ?? "";
        const queryParam = `?returnUrl=${onlineClient}${this.baseHref}start`
        window.location.href = route.data['externalUrl'] + queryParam;
        return true;
    }

}
