import { DestroyRef, Injectable, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatDialog } from "@angular/material/dialog";
import { Subject, filter } from "rxjs";
import { BaseComponent } from "src/app/base-component";
import { VersionManagementService } from "src/app/start/offline/pre-release-home/services/version-management/version-management-service";
import { SurveyComponent } from "../components/survey.component";
import { IUserMessage } from "../../../user-message/contracts/user-message.interface";
import { IAnswerDto, ISurveyResponseDto, IUserRequest } from "../../../user-message/contracts/user-response.interface";
import { SurveyResponseModel } from "../../shared/models/survey-response.model";
import { SurveyModel } from "../../shared/models/survey.model";
import { UserMessageHubService } from "../../../user-message/services/user-message.hub.service";

@Injectable({
    providedIn: "root",
})
export class SurveyClientService {
    private readonly surveyMessageType = "survey";
    private destroyRef = inject(DestroyRef);
    private availableSurveySubject = new Subject<SurveyModel>();
    public availableSurvey$ = this.availableSurveySubject.asObservable();

    constructor(
        private userMessageHub: UserMessageHubService,
        private matDialog: MatDialog,
        private versionManagementService: VersionManagementService
        ) { }

    public async start(): Promise<void> {
        this.setupSurveyListener();
    }

    public openSurvey(survey: SurveyModel): void {
        this.matDialog.open(SurveyComponent, {
            data: survey,
        });
    }

    public async saveSurvey(survey: SurveyModel, responses: SurveyResponseModel[]): Promise<void> {

        const offlineMode = BaseComponent.offlineMode;
        const releaseName = await this.versionManagementService.getReleaseNameAsync();
        const answers: IAnswerDto[] = responses.map(x => ({ questionId: x.questionId, response: x.response }));

        const requestContent: ISurveyResponseDto = {
            surveyId: survey.id,
            variant: offlineMode ? "offline" : "online",
            hiCommandVersion: releaseName,
            answerState: "answered",
            answers: answers
        }

        const request: IUserRequest = {
            requestType: "survey",
            content: requestContent
        }

        await this.userMessageHub.sendUserRequest(request).then(() => {
            // If successful, remove the current survey
            this.availableSurveySubject.next(null);
        });
    }

    public async saveSurveyAnswer(survey: SurveyModel, answerState: string): Promise<void> {
        const requestContent: ISurveyResponseDto = {
            surveyId: survey.id,
            variant: "",
            hiCommandVersion: "",
            answerState: answerState,
            answers: []
        }

        const request: IUserRequest = {
            requestType: "survey",
            content: requestContent
        }

        await this.userMessageHub.sendUserRequest(request).then(() => {
            // If successful, remove the current survey
            this.availableSurveySubject.next(null);
        });
    }

    private setupSurveyListener(): void {
        this.userMessageHub.userMessage$
        .pipe(takeUntilDestroyed(this.destroyRef), filter(x => x.messageType === this.surveyMessageType))
        .subscribe((userMessage) => {
            const surveyModel = this.convertMessageContent(userMessage);
            if (surveyModel) {
                this.availableSurveySubject.next(surveyModel);

                this.openSurvey(surveyModel);
            }
        });
    }

    private convertMessageContent(message: IUserMessage): SurveyModel {
        try {
            if (!message.content) {
                console.warn("Received survey message without content", message);
                return null;
            }
    
            const content = JSON.parse(message.content.toString());
            if (!content.id) {
                console.warn("Received survey message without id", message);
                return null;
            }
    
            if (!content.questions || !content.questions.length) {
                console.warn("Received survey message without questions", message);
                return null;
            }
    
            const survey = Object.assign(new SurveyModel(), content as SurveyModel);
            return survey;
        } catch (error) {
            console.warn("Failed to convert message content", message, error?.message);
        }
    }
}
