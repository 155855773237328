import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { ConnectedAsset } from "../../models/remote-connect-models";
import { Urls } from "src/app/globals/urls";
import { Connection } from "src/app/core/connections/models/connection.model";

@Injectable({
    providedIn: "root"
})
export class RemoteConnectionHttpService {
    constructor(private http: HttpClient) {
    }

    public getConnectedAssets(): Observable<ConnectedAsset[]> {
        return this.http.get<ConnectedAsset[]>(Urls.REMOTE_CONNECTION_ASSETS);
    }

    public getPreScanInformation(): Observable<string> {
        // return this.http.get<string>(Urls.REMOTE_PRESCAN);
        return this.http.get(Urls.REMOTE_PRESCAN, { responseType: "text" });

    }

    public createHiSpeedRemoteConnection(craneSerialNumber: string): Observable<Connection> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = {
            craneSerialNumber
        };
        return this.http.post<Connection>(Urls.CREATE_HISPEED_REMOTE_CONNECTION, body, { headers: requestHeaders });
    }

    public createBlackBaronRemoteConnection(craneSerialNumber: string): Observable<Connection> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = {
            craneSerialNumber
        };
        return this.http.post<Connection>(Urls.CREATE_BLACKBARON_REMOTE_CONNECTION, body, { headers: requestHeaders });
    }

    public createRemoteConnection(craneSerialNumber: string): Observable<Connection> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = {
            craneSerialNumber
        };
        return this.http.post<Connection>(Urls.CREATE_REMOTE_CONNECTION, body, { headers: requestHeaders });
    }
}
