<div class="top-bar">
    <mat-icon>language</mat-icon>
    <span class="mat-subtitle-1" style="margin: unset;" i18n>Select Language</span>
</div>
<mat-selection-list [multiple]="false" class="middle">
    <ng-container *ngFor="let locale of locales; let i = index;">
        <mat-list-item tabindex="0" (click)="selectItem(locale.code)" [class.selected]="locale.code === selectedItem">
            <span>
                {{ locale.name }}
            </span>
            <span matListItemMeta>
                <mat-icon *ngIf="locale.code === selectedItem">
                    check
                </mat-icon>
            </span>
        </mat-list-item>
        <mat-divider *ngIf="i !== locales.length - 1"></mat-divider>
    </ng-container>
</mat-selection-list>
<div class="bottom-bar" mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" [mat-dialog-close] i18n>
        Cancel
    </button>
    <button mat-flat-button color="primary" [mat-dialog-close]="selectedItem" i18n>
        Ok
    </button>
</div>
