import { Component, OnInit } from "@angular/core";
import { SpinnerService } from "src/app/core/dialogs/services/spinner.service";
import { MessageService } from "src/app/core/messages/message.service";
import { ReportHttpService } from "../../../hiset/services/report.http.service";
import { HiSetReport } from "../../../hiset/models/hiset-report.model";
import * as fileSaver from "file-saver";
import { ProductHttpService } from "src/app/hiset/services/product.http.service";
import { IProductIdentifier } from "src/app/hiset/contracts/product-identifier.interface";
import { Observable, lastValueFrom, map, startWith } from "rxjs";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { FormControl } from "@angular/forms";

@Component({
    selector: "hic-hiset-report",
    templateUrl: "./hiset-report.component.html",
    styleUrls: ["./hiset-report.component.scss"]
})
export class HiSetReportComponent implements OnInit {
    public showDarkbox = false;
    public darkboxMessage = "";
    public products: IProductIdentifier[] = [];
    public selectedProduct: IProductIdentifier;
    public filteredProducts$: Observable<IProductIdentifier[]>;
    public myControl: FormControl = new FormControl();

    constructor(
        private reportHttpService: ReportHttpService,
        private spinnerService: SpinnerService,
        private messageService: MessageService,
        private productHttp: ProductHttpService,
    ) { }

    ngOnInit() {
        this.getProducts();
        this.setupProductsFilter();
    }

    public async getUsedParameters(): Promise<void> {
        try {
            this.setDarkBoxText($localize`Generating Used Parameters Report...`, true);
            const gidResponse = await this.reportHttpService.getUsedParameters();
            this.downloadFile(gidResponse);
        }
        catch {
            this.messageService.sendWarning($localize`Failed to generate Used Parameters Report`);
        }
        finally {
            this.setDarkBoxText("", false);
        }
    }

    public async getUnusedGids(): Promise<void> {
        try {
            this.setDarkBoxText($localize`Generating Unused Gids Report...`, true);
            const unusedGidResponse = await this.reportHttpService.getUnusedGids();
            this.downloadFile(unusedGidResponse);
        }
        catch {
            this.messageService.sendWarning($localize`Failed to generate Unused Gids Report`);
        }
        finally {
            this.setDarkBoxText("", false);
        }
    }

    public async getOutOfRangeParameters(): Promise<void> {
        try {
            this.setDarkBoxText($localize`Generating Parameters Out Of Range Report...`, true);
            const unsetParameterResponse = await this.reportHttpService.getOutOfRangeParameters();
            this.downloadFile(unsetParameterResponse);
        }
        catch {
            this.messageService.sendWarning($localize`Failed to generate Parameters Out Of Range Report`);
        }
        finally {
            this.setDarkBoxText("", false);
        }
    }

    public async getProductReport(selection: MatAutocompleteSelectedEvent | IProductIdentifier): Promise<void> {

        if (selection instanceof MatAutocompleteSelectedEvent) {
            this.selectedProduct = selection.option.value as IProductIdentifier;
        } else {
            this.selectedProduct = selection;
        }

        if (this.selectedProduct) {
            this.setDarkBoxText($localize`Generating Product Report...`, true);
            try {
                const productReportResponse = await this.reportHttpService.getProductReport(this.selectedProduct);
                this.downloadFile(productReportResponse);
            } finally {
                this.setDarkBoxText("", false);
            }
        }
    }

    public displayProductName(product?: IProductIdentifier): string | undefined {
        return product ? product.name : undefined;
    }

    private async getProducts(): Promise<void> {
        try {
            this.showDarkbox = true;
            this.darkboxMessage = $localize`:@@parameter.labels.get-products:Getting Products`;
            this.products = await lastValueFrom(this.productHttp.getEnabledProducts());
        } finally {
            this.setDarkBoxText("", false);
        }
    }

    private setupProductsFilter(): void {
        this.filteredProducts$ = this.myControl.valueChanges
            .pipe(
                startWith(""),
                map(val => this.filterProducts(val))
            );
    }

    private downloadFile(report: HiSetReport): void {
        if (report !== undefined) {
            const reportFile = new Blob([report.data], { type: "text/csv" });
            const url = window.URL.createObjectURL(reportFile);
            fileSaver.saveAs(url, report.name);
        } else {
            this.messageService.sendWarning($localize`Failed to download report`);
        }
    }

    private setDarkBoxText(text: string, isDisplayed: boolean): void {
        this.darkboxMessage = text;
        this.showDarkbox = isDisplayed;
    }

    private filterProducts(productFilter: IProductIdentifier | string): IProductIdentifier[] {

        if (typeof productFilter === "string") {
            return this.products.filter(option =>
                option.name.toLowerCase().includes(productFilter.toLowerCase()));
        }

        if (typeof productFilter === "object") {

            return this.products.filter(option =>
                option.name.toLowerCase().includes(productFilter.name.toLowerCase()));
        }
    }
}
