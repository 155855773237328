import { Urls } from "src/app/globals/urls";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { HiSetReport } from "../models/hiset-report.model";
import { IProductIdentifier } from "../contracts/product-identifier.interface";

@Injectable({
    providedIn: "root"
})

export class ReportHttpService {
    constructor(private http: HttpClient) { }

    public async getUsedParameters(): Promise<HiSetReport> {
        return await firstValueFrom(this.http.get<HiSetReport>(Urls.HISET_REPORT_USED_PARAMETERS));
    }

    public async getUnusedGids(): Promise<HiSetReport> {
        return await firstValueFrom(this.http.get<HiSetReport>(Urls.HISET_REPORT_UNUSED_GIDS));
    }

    public async getOutOfRangeParameters(): Promise<HiSetReport> {
        return await firstValueFrom(this.http.get<HiSetReport>(Urls.HISET_REPORT_PARAMETERS_OUT_OF_RANGE));
    }

    public async getProductReport(product: IProductIdentifier): Promise<HiSetReport> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = product;
        return await firstValueFrom(this.http.post<HiSetReport>(Urls.HISET_REPORT_PRODUCT, body, { headers: requestHeaders }));
    }

}
