import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BaseComponent } from "src/app/base-component";
import { CommunicationProtocol } from "src/app/core/connections/enums/communication-protocol.enum";
import { ControlSystemGeneration } from "src/app/core/connections/enums/control-system-generation.enum";
import { ConnectionData } from "src/app/core/connections/models/connection-data.model";
import { MessageService } from "src/app/core/messages/message.service";
import { SystemEventService } from "src/app/core/messages/system-event.service";
import { ConditionsService } from "src/app/core/space/services/conditions/conditions.service";
import { SystemEventStatusCode } from "src/app/globals/enums/core-enums";
import { ApplicationStateHubService } from "../../services/application-state-hub.service";
import { TokenRefreshService } from "../../services/token-refresh/token-refresh.service";

@Component({
    selector: "hic-app",
    templateUrl: "./app-online.component.html",
    styleUrls: ["../../styles/app-variant.component.scss"],
})
export class AppOnlineComponent extends BaseComponent implements OnInit, OnDestroy {
    private componentDestroyed$: Subject<boolean> = new Subject<boolean>();
    public currentConnection: ConnectionData;
    public logoutURL = "";
    public isGidlistOverridden$: Observable<boolean>;

    constructor(
        conditionsService: ConditionsService,
        changeDetector: ChangeDetectorRef,
        private router: Router,
        private systemEventService: SystemEventService,
        private messageService: MessageService,
        private applicationStateHttpService: ApplicationStateHubService,
        private tokenRefreshService: TokenRefreshService
    ) {
        super(conditionsService, changeDetector);
        BaseComponent.offlineMode = false;
        this.isGidlistOverridden$ = this.applicationStateHttpService.isGidListOverridden$;
        this.tokenRefreshService.startCycle();
    }

    async ngOnInit(): Promise<void> {
        this.conditionsService.connectionChanged$.pipe(takeUntil(this.componentDestroyed$)).subscribe((event) => {
            this.currentConnection = event;
        });

        this.systemEventService.onSystemEvent$.subscribe(async (systemEvent) => {
            if (systemEvent && systemEvent.statusCode === SystemEventStatusCode.SessionTimeout) {
                if (systemEvent.connectionId === this.conditionsService.connectionId) {
                    this.messageService.sendAlert(systemEvent.message);
                    this.conditionsService.connectionId = "";
                    this.conditionsService.controlSystemGeneration = ControlSystemGeneration.Undefined;
                    this.conditionsService.communicationProtocol = CommunicationProtocol.NotConnected;
                }
                await this.conditionsService.getServerSideSessions();
                this.conditionsService.getServerSideConnectionMode();
            }
        });
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
    }

    public get routeName(): string {
        // -> Splits string at capital letters. E.g. "VariablesPage" becomes "Variables Page";
        const str = this.router.url.replace("/", "").split(/(?=[A-Z])/).join(" ");
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
}
