import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatExpansionPanel } from "@angular/material/expansion";
import { Subscription } from "rxjs";
import { BaseComponent } from "src/app/base-component";
import { ConnectionData } from "src/app/core/connections/models/connection-data.model";
import { ConditionsService } from "src/app/core/space/services/conditions/conditions.service";
import { VslPayloadDiagramDto, VslReportDiagramDto } from "src/app/safety/stability/vsl-report/components/vsl-report-graph/models/vsl-report-diagram-dto.model";
import { InformationData } from "../models/information-mini-display.model";
import { InformationMiniDisplayService } from "../services/information-mini-display.service";

@Component({
    selector: "hic-information-mini-display",
    templateUrl: "./information-mini-display.component.html",
    styleUrls: ["./information-mini-display.component.scss"],
})
export class InformationMiniDisplayComponent extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild("collapsable", { static: true }) public parent: MatExpansionPanel;

    private pollInformationReadySubscription: Subscription;

    private informationReadySubscription: Subscription;
    public payloadDiagrams: VslPayloadDiagramDto[];
    public stabilityDiagrams: VslReportDiagramDto[];
    public showLoader = true;
    private isCollapsed = true;

    public get allowOpen(): boolean {
        return this.conditionsService.isDataSourceConnected;
    }

    public information: InformationData;
    public areDiagramsVisible = false;

    public get payTypes(): string {
        let paytypes = "";

        if (this.hasPayTypes()) {
            for (const paytype of this.information.payType) {
                paytypes += paytype + " ";
            }

        } else {
            paytypes = $localize `:@@mini-information.nopaytype:No Pay Types found`;
        }

        return paytypes.trimRight();
    }

    public get systemTypes(): string {
        let systemtypes = "";

        if (this.hasSystemTypes()) {
            for (const systemtype of this.information.systemType) {
                systemtypes += systemtype + " ";
            }

        } else {
            systemtypes = $localize `:@@mini-information.nosystemtype:No System Types found`;
        }

        return systemtypes.trimRight();
    }

    public constructor(public conditionsService: ConditionsService,
        changeDetector: ChangeDetectorRef,
        private informationService: InformationMiniDisplayService,
    ) {
            super(conditionsService, changeDetector);
        }

    public ngOnInit(): void {
        this.setupCollapsedSubscription();
        this.setupInformationSubscription();
        this.setupPollingSubscription();

        this.conditionsService.connectionChanged$.subscribe((response: ConnectionData) => {
            if (response.isCompleted) {
                this.informationService.getDataModel();
            }
        });

        if (this.conditionsService.isDataSourceConnected) {
            this.informationService.getDataModel();
        }
    }

    public ngOnDestroy(): void {
        this.informationService.stopPolling();
        if (this.pollInformationReadySubscription !== undefined) {
            this.pollInformationReadySubscription.unsubscribe();
        }
        if (this.informationReadySubscription !== undefined) {
            this.informationReadySubscription.unsubscribe();
        }
    }

    private setupCollapsedSubscription(): void {
        this.parent.expandedChange.subscribe((collapsed: boolean) => {
            this.isCollapsed = collapsed;
            if (collapsed) {
                this.informationService.stopPolling();
            } else {
                // Do not start polling if datasource is static
                if (!this.conditionsService.isConnectionStatic) {
                    this.informationService.startPolling();
                }
            }
        });
    }

    public setupPollingSubscription(): void {
        if (this.pollInformationReadySubscription === undefined) {
            this.pollInformationReadySubscription = this.informationService.pollInformationReady.subscribe((ready: boolean) => {
                this.information = this.informationService.information;
            });
        }
    }

    private setupInformationSubscription(): void {
        if (this.informationReadySubscription === undefined) {
            this.informationReadySubscription = this.informationService.informationReady.subscribe((ready: boolean) => {
                this.information = this.informationService.information;
                this.showLoader = false;
                if (this.isCollapsed === false) {
                    // Do not start polling if datasource is static
                    if (!this.conditionsService.isConnectionStatic) {
                        this.informationService.startPolling();
                    }
                }
            });
        }
    }

    public async showDiagrams(): Promise<void> {
        this.areDiagramsVisible = true;
    }

    public hideDiagrams(): void {
        this.areDiagramsVisible = false;
    }

    private hasPayTypes(): boolean {
        if (this.information.payType !== undefined) {
            return this.information.payType.length > 0;
        }
        return false;
    }

    private hasSystemTypes(): boolean {
        if (this.information.systemType !== undefined) {
            return this.information.systemType.length > 0;
        }
        return false;
    }
}
