import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { BaseComponent } from "src/app/base-component";
import { ButtonType } from "src/app/core/dialogs/models/button-type.enum";
import { DialogResult } from "src/app/core/dialogs/models/dialog-result.enum";
import { DialogService } from "src/app/core/dialogs/services/dialog.service";
import { UserRightsService } from "src/app/core/security/shared/services/user-rights/user-rights.service";
import { ConditionsService } from "src/app/core/space/services/conditions/conditions.service";
import { RemoteAccessLevel } from "../../models/remote-connect-models";
import { VersionDto } from "../../offline/pre-release-home/models/version.dto";
import { CanInterfaceService } from "../../offline/pre-release-home/services/can-interface/can-interface.service";
import { VersionManagementService } from "../../offline/pre-release-home/services/version-management/version-management-service";
import { GenericMessageService } from "src/app/core/messages/generic-message.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "hic-start-page",
    templateUrl: "./start-page.component.html",
    styleUrls: ["./start-page.component.scss"],
    providers: [CanInterfaceService]
})
export class StartPageComponent extends BaseComponent implements OnInit, OnDestroy {
    public noConnection = true;
    public remoteAccessAllowed = false;
    public blackBaronAccessAllowed = false;
    public versionDetails = new VersionDto();

    public constructor(
        conditionsService: ConditionsService,
        changeDetector: ChangeDetectorRef,
        private versionManagementService: VersionManagementService,
        private dialogService: DialogService,
        private userRightsService: UserRightsService,
        private httpClient: HttpClient,
        private genericMessageService: GenericMessageService,
        private matDialog: MatDialog,
    ) {
        super(conditionsService, changeDetector);
    }

    public async ngOnInit(): Promise<void> {
        if (this.offlineMode) {
            this.checkForNewVersionAsync();
        }
        this.remoteAccessAllowed = await this.userRightsService.isComponentAllowed(RemoteAccessLevel.read)
        || await this.userRightsService.isComponentAllowed(RemoteAccessLevel.write);

        this.blackBaronAccessAllowed = await this.userRightsService.isComponentAllowed(RemoteAccessLevel.blackBaron);

        this.genericMessageService.connectToHub();
    }

    public noConnections(value: boolean): void {
        this.noConnection = value;
    }

    private async checkForNewVersionAsync(): Promise<void> {
        this.versionDetails = await this.versionManagementService.checkNewVersionAvailable();
        if (this.versionDetails.urlPath) {
            let result: DialogResult;
            if (this.versionDetails.isMandatory) {
                result = await this.dialogService.showModalDialogAsync({
                        content: $localize `:@@start.new-version-mandatory:You must upgrade the program before use!\nWarning: HiCommand will shut down.`,
                        confirmativeButton: ButtonType.download
                });
            } else {
                result = await this.dialogService.showModalDialogAsync({
                        content: $localize `:@@start.new-version-available:New version available!\nWarning: HiCommand will shut down.`,
                        confirmativeButton: ButtonType.download,
                        dismissiveButton: ButtonType.cancel
                });
            }
            if (result === DialogResult.confirm) {
                await this.dialogService.showModalDialogAsync({
                    content: $localize `:@@start.new-version-stop-warning:Warning: HiCommand will shut down. Install the new version once downloaded.`,
                    confirmativeButton: ButtonType.ok
                });
                firstValueFrom(this.httpClient.get("/api/Shutdown/shutdown"));
                this.relocateToDownloadPage();
            }

        }
}

    private relocateToDownloadPage(): void {
        window.location.href = this.versionDetails.urlPath;
    }
}
