<!--Definitions of all icons used in the app-->
<hic-svg-icon-defs style="position: absolute; width: 0; height: 0;"></hic-svg-icon-defs>

<hic-asset-preloader
    [assetPaths]="['/assets/symbols/chevron-down.svg', 'assets/symbols/warning.svg', 'assets/symbols/done.svg']"
></hic-asset-preloader>

<div class="hic-body-container">
    <div class="hic-top-container">
        <hic-navbar [offlineVersion]="false"></hic-navbar>
    </div>
    <hic-top-menu></hic-top-menu>
    <div class="hic-main-container">
        <div class="hic-workarea-container">
            <hic-exception-alert></hic-exception-alert>
            <div class="main-outlet">
                <router-outlet></router-outlet>
            </div>
        </div>
        <div class="hic-aside-container">
            <hic-info-display></hic-info-display>
        </div>
    </div>
</div>

<div class="info-overlay" *ngIf="(isGidlistOverridden$ | async)" i18n>
    <div class="info-content">
        User defined gid-list in use!
    </div>
</div>
