import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
import { RemoteConnectionHttpService } from "./remote-connection.http.service";
import { ConnectedAsset } from "../../models/remote-connect-models";
import { ConditionsService } from "src/app/core/space/services/conditions/conditions.service";
import { CommunicationProtocol } from "src/app/core/connections/enums/communication-protocol.enum";
import { ControlSystemGeneration } from "src/app/core/connections/enums/control-system-generation.enum";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class RemoteConnectionService {
    public connectedAssets: ConnectedAsset[];
    public prescanInformation: string;
    public assetsReady = new EventEmitter<boolean>();
    public prescanInformationReady = new EventEmitter<boolean>();
    public remoteHiSpeedConnectionResult = new Subject<boolean>();
    public blackBaronConnectionResult = new Subject<boolean>();

    // Constructor
    constructor(private remoteConnectionHttpService: RemoteConnectionHttpService,
        private conditionsService: ConditionsService) {
        this.connectedAssets = [];
        this.prescanInformation = "";
    }

    public getConnectedAssets() {
        this.remoteConnectionHttpService.getConnectedAssets().subscribe((response: ConnectedAsset[]) => {
            this.connectedAssets = response;
            this.assetsReady.emit(true);
        }, err => {
            console.log(err);
            this.assetsReady.emit(false);
        });
    }

    public getPreScanInformation() {
        this.remoteConnectionHttpService.getPreScanInformation().subscribe((response: string) => {
            this.prescanInformation = response;
            this.prescanInformationReady.emit(true);
        }, err => {
            console.log(err);
            this.prescanInformationReady.emit(false);
        });
    }

    public createHiSpeedRemoteConnection(gatewayId: string) {
        this.remoteConnectionHttpService.createHiSpeedRemoteConnection(gatewayId).subscribe(connection => {
            this.conditionsService.connectionId = connection.id.value;
            this.conditionsService.controlSystemGeneration = connection.controlSystemGeneration;
            this.conditionsService.communicationProtocol = connection.protocol;

            this.conditionsService.getServerSideConnectionMode();

            this.remoteHiSpeedConnectionResult.next(true);
        }, (error: HttpErrorResponse) => {
            this.conditionsService.handleConnectionError(error);

            this.remoteHiSpeedConnectionResult.next(false);
        });
    }

    public createBlackBaronRemoteConnection(blackBaronId: string): void {
        this.remoteConnectionHttpService.createBlackBaronRemoteConnection(blackBaronId).subscribe(connection => {
            this.conditionsService.connectionId = connection.id.value;
            this.conditionsService.controlSystemGeneration = connection.controlSystemGeneration;
            this.conditionsService.communicationProtocol = connection.protocol;

            this.conditionsService.getServerSideConnectionMode();

            this.blackBaronConnectionResult.next(true);
        }, (error: HttpErrorResponse) => {
            this.conditionsService.handleConnectionError(error);

            this.blackBaronConnectionResult.next(false);
        });
    }

    public createRemoteConnection(assetId: string) {
        this.remoteConnectionHttpService.createRemoteConnection(assetId).subscribe(connection => {
            this.conditionsService.connectionId = connection.id.value;
            this.conditionsService.controlSystemGeneration = ControlSystemGeneration.X4;
            this.conditionsService.communicationProtocol = CommunicationProtocol.HiConnect;

            this.conditionsService.getServerSideConnectionMode();
        }, (error: HttpErrorResponse) => {
            this.conditionsService.handleConnectionError(error);
        });
    }
}
