import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "hic-authorization",
  templateUrl: "./authorization.component.html",
  styleUrls: ["./authorization.component.scss"]
})
export class AuthorizationComponent implements OnInit {

    constructor(private router: Router) { }

    ngOnInit(): void {
        // the angular Router cannot by default reroute to a url
        // outside of the routes defined for the route, so some
        // hackish reroute logic has be implemented.
        // More info in offline-routing.component.ts
        this.router.navigate(["identity"]);
    }
}
