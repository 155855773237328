import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Urls } from "src/app/globals/urls";
import { ChannelInformation } from "src/app/core/connections/serial/models/channel-information.interface";

@Injectable({
  providedIn: "root"
})
export class CanInterfaceHttpService {

  constructor(private httpClient: HttpClient) { }

  public getCanInterfaceInformation(): Observable<ChannelInformation[]> {
    return this.httpClient.get<ChannelInformation[]>(Urls.CAN_INTERFACE_PATH);
  }
}
