<div class="hic-info-flexcontainer" *ngIf="isConnected && isLoggedIn() && (showInfoPanel$ | async)">
    <div class="hic-info-display" [class.overlapping]="(panelType$ | async) === 'overlapping'">
        <div class="handle-container">
            <div id="hic-aside-handle" (click)="togglePanel()" (mouseenter)="showHandle()" (mouseleave)="hideHandle()">
                <mat-icon [fontIcon]="(panelState$ | async) === 'expanded' ? 'arrow_forward_ios' : 'arrow_back_ios_new'"></mat-icon>
            </div>
        </div>
        <div class="panel-list" [@expandPanel]="panelState$ | async">
            <div class="panel-container" *ngIf="(panelState$ | async) === 'expanded'">
                <hic-errors-mini-display></hic-errors-mini-display>
                <hic-variables-mini-display></hic-variables-mini-display>
                <hic-information-mini-display></hic-information-mini-display>
            </div>
        </div>
    </div>
</div>
