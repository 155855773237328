export class TranslationHelper {
    public static getTranslation(translation: { [key: string]: string }, locale: string): string {
        if (translation[locale]) {
            return translation[locale];
        }

        if (translation["en-GB"]) {
            return translation["en-GB"];
        }

        // Find the first title that is not undefined
        for (const key in translation) {
            if (translation[key]) {
                return translation[key];
            }
        }

        return undefined;
    }
}