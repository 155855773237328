import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { SessionInterceptor } from "../sessions/interceptors/session.interceptor";
import { ExceptionInterceptor } from "../exception-handling/interceptors/exception.interceptor";
import { SessionStorage } from "src/app/globals/session-storage";
import { IdentityInterceptor } from "src/app/core/identity/identity.interceptor";
import { UnauthenticatedInterceptor } from "../exception-handling/interceptors/unauthenticated.interceptor";
import {APP_BASE_HREF, PlatformLocation} from '@angular/common';

export const coreProviders = [
        { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useExisting: ExceptionInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: IdentityInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UnauthenticatedInterceptor, multi: true},
        { provide: SessionStorage, useValue: window.sessionStorage },
        { provide: "ORIGIN_URL", useValue: location.origin },
        { provide: APP_BASE_HREF, useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(), deps: [PlatformLocation]},
        
    ];
