import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { DownloadUrl } from "src/app/start/models/download-url";
import { DownloadOfflineHttpService } from "./download-offline.http.service";

@Injectable({
    providedIn: "root"
})
export class DownloadOfflineService {
    constructor(private httpService: DownloadOfflineHttpService) { }

    public async getDownloadUrl(): Promise<DownloadUrl> {
        return await firstValueFrom(this.httpService.getDownloadUrl());
    }
}
