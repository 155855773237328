import { CommonModule } from "@angular/common";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { CoreModule } from "../../core/core.module";
import { SharedModule } from "../../shared/shared.module";
import { QuestionComponent } from "../client/components/question/question.component";
import { SurveyComponent } from "../client/components/survey.component";
import { SurveyClientService } from "./services/survey-client.service";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        CoreModule,
        FormsModule,

        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule
    ],
    declarations: [
        SurveyComponent,
        QuestionComponent
    ],
    exports: [
        SurveyComponent,
        QuestionComponent
    ],
    providers: [
        SurveyClientService,
        {
            provide: APP_INITIALIZER,
            useFactory: (service: SurveyClientService) => (): Promise<void> => service.start(),
            deps: [SurveyClientService],
            multi: true
        }
    ],
}) export class SurveyClientModule { }
