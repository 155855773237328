import { Injectable, EventEmitter } from "@angular/core";
import { DueDateHttpService } from "./due-date.http.service";

@Injectable({
    providedIn: 'root'
})
export class DueDateService {

    //    public dueSoon = new EventEmitter<boolean>();
    public dueNow: boolean=false;
    public dueSoon: boolean = false;
    public daysToDue: string = "";
    public dueSoonReady = new EventEmitter<boolean>();
    public dueNowReady = new EventEmitter<boolean>();
    public daysToDueReady = new EventEmitter<boolean>();


    constructor(private dueDateHttpService: DueDateHttpService) {
        this.dueNow = false;
        this.dueSoon = false;
    }

    public getDueSoon() {
        this.dueSoon = undefined;
            this.dueDateHttpService.GetDueSoon().subscribe(_dueSoon => {
            this.dueSoon = _dueSoon;
            this.dueSoonReady.emit(true);
        }, err => {
            console.log("Error " + err);
            this.dueSoonReady.emit(false);
        })

    }

    public getDueNow() { //Not currently used. When after due-date the progam simply don´t start.
        this.dueNow = undefined;
            this.dueDateHttpService.GetDueNow().subscribe(_dueNow => {
            this.dueNow = _dueNow;
            this.dueNowReady.emit(true);
        }, err => {
            console.log("Error " + err);
            this.dueNowReady.emit(false);
        })

    }

    public getDaysToDue() {
        this.daysToDue = undefined;
            this.dueDateHttpService.GetDaysToDue().subscribe(_daysToDue => {
            this.daysToDue = _daysToDue;
            this.daysToDueReady.emit(true);
        }, err => {
            console.log("Error " + err);
            this.daysToDueReady.emit(false);
        })

    }




}
