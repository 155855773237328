import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoggedOutComponent } from "src/app/core/security/logged-out/components/logged-out.component";
import { LogoutComponent } from "src/app/core/security/login/components/logout.component";
import { RouteAllowedGuard } from "src/app/core/security/shared/guards/route-allowed.guard";
import { HiSetReportComponent } from "src/app/start/online/hiset-report/hiset-report.component";
import { environment } from "src/environments/environment";
import { AuthorizationComponent } from "../authorization/authorization.component";
import { RedirectGuard } from "../redirect.guard";
import { StartPageComponent } from "../start-page/components/start-page.component";
import { DownloadOfflineComponent } from "./download-offline/Components/download-offline.component";
import { OnlineLoginCallbackComponent } from "./online-login-callback/online-login-callback.component";

const routes: Routes = [
    { path: "", component: AuthorizationComponent }, // routes to  identity endpoint in backend
    {
        path: "identity", // allow the routing to the backend identity endpoint
        canActivate: [RedirectGuard],
        component: RedirectGuard,
        data: {
            externalUrl: environment.onlineLoginUrl
        }
    },
    // when logged in, redirects to this route
    { path: "start", component: StartPageComponent },
    { path: "tuning", loadChildren: () => import("../../tuning/tuning-routing.module").then(m => m.TuningRoutingModule) },
    { path: "hardware", loadChildren: () => import("../../hardware/hardware.module").then(m => m.HardwareModule) },
    { path: "safety", loadChildren: () => import("../../safety/safety-routing.module").then(m => m.SafetyRoutingModule) },
    { path: "overview", loadChildren: () => import("../../overview/overview-routing.module").then(m => m.OverviewRoutingModule) },
    { path: "setup", loadChildren: () => import("../../setup/setup-routing.module").then(m => m.SetupRoutingModule) },
    { path: "dev", loadChildren: () => import("../../dev/dev.module").then(m => m.DevModule) },
    { path: "admin", loadChildren: () => import("../../admin/admin.module").then(m => m.AdminModule) },
    { path: "hiset", loadChildren: () => import("../../hiset/hiset.module").then(m => m.HiSetModule) },
    { path: "hiset-report", component: HiSetReportComponent },
    { path: "logout", component: LogoutComponent },
    { path: "loggedout", component: LoggedOutComponent },
    { path: "onlinelogincallback", component: OnlineLoginCallbackComponent },
    { path: "hitest/admin", loadChildren: () => import("../../hitest/admin/hitest-admin.module").then(m => m.HiTestAdminModule) },
    {
        path: "hitest/super-ad",
        loadChildren: () => import("../../hitest/super-ad/hitest-super-ad.module").then(m => m.HiTestSuperAdModule)
    },
    { path: "performance", loadChildren: () => import("../../performance/performance.module").then(m => m.PerformanceModule) },
    { path: "download-offline", component: DownloadOfflineComponent, canActivate: [RouteAllowedGuard] },
    {
        path: "interactive-diagrams", loadChildren: () =>
            import("../../interactive-diagrams/interactive-diagrams.module").then(m => m.InteractiveDiagramsModule)
    },
    { path: "ce-certs", loadChildren: () => import("../../ce-certs/ce-certs.module").then(m => m.CeCertsModule) },
    { path: "hiview", loadChildren: () => import("../../hiview/hiview.module").then(m => m.HiViewModule) },
    { path: "**", redirectTo: "start" }
    // TODO - where should the user be routed when a unregistered route is encountered?
    // https://jira.shared.tds.cargotec.com/browse/HIAA-2945
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { enableTracing: false })
    ],
    exports: [RouterModule]
})
export class OnlineRoutingModule { }
