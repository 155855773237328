export class GenericMessageDto {
    public type: number;
    public message: string;
}

export enum GenericMessageType {
    information,
    warning,
    error
}
