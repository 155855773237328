import { ChangeDetectorRef, Component } from "@angular/core";
import {
    BehaviorSubject,
    combineLatest,
    concat,
    debounceTime,
    distinctUntilChanged,
    map,
    Observable,
    of,
    Subject,
    switchMap,
    tap
} from "rxjs";
import { BaseComponent } from "src/app/base-component";
import { GenericMessageService } from "src/app/core/messages/generic-message.service";
import { SystemEventService } from "src/app/core/messages/system-event.service";
import { UserInformationService } from "src/app/core/security/shared/services/userinformation/userinformation.service";
import { ConditionsService } from "src/app/core/space/services/conditions/conditions.service";
import { CraneSerialNumberService } from "src/app/shared/services/crane-serial-number.service";
import { RemoteConnectionService } from "src/app/start/services/remote-connection/remote-connection.service";

@Component({
    selector: "hic-remote-connection",
    templateUrl: "./remote-connection.component.html",
    styleUrls: ["./remote-connection.component.scss"]
})
export class RemoteConnectionComponent extends BaseComponent {
    public selectedGateway = ""; // Use cargotec-gw-TSBI04015122 for the dev gateway.
    public validatingSelectedGateway = new BehaviorSubject(false);
    public validatingGateway$: Observable<boolean> = this.validatingSelectedGateway.asObservable();

    public validGateway$: Observable<boolean>;

    private gatewayChangedSubject = new Subject<string>();
    private gatewayChanged$ = this.gatewayChangedSubject.asObservable();

    public canConnectToGateway$: Observable<boolean>;

    public isConnecting = false;
    public inputMaxLength = 100;
    public gatewayInputTooLong = false;

    constructor(
        public userInformationService: UserInformationService,
        conditionsService: ConditionsService,
        private remoteConnectionService: RemoteConnectionService,
        changeDetector: ChangeDetectorRef,
        private craneSerialNumberService: CraneSerialNumberService,
        private genericMessageService: GenericMessageService,
        private systemEventService: SystemEventService
    ) {
        super(conditionsService, changeDetector);

        const validateSerialNumber = (value: string): Observable<boolean> =>
            this.craneSerialNumberService.validateSerialNumber(value).pipe(map(x => !!x.status));

        const gatewayValid = this.gatewayChanged$.pipe(
            distinctUntilChanged(),
            debounceTime(800),
            tap(_ => this.validatingSelectedGateway.next(true)),
            switchMap(value => value && value.length > 0 && value !== "" && validateSerialNumber(value)),
            tap(_ => this.validatingSelectedGateway.next(false))
        );

        const gatewayValidInitalFalse = concat(of(false), gatewayValid);
        const gatewayValidInitalTrue = concat(of(true), gatewayValid);

        this.canConnectToGateway$ = combineLatest([this.validatingGateway$, gatewayValidInitalFalse]).pipe(
            map(([validating, validGateway]) => !validating && !!validGateway)
        );

        this.validGateway$ = combineLatest([this.validatingGateway$, gatewayValidInitalTrue]).pipe(
            map(([validating, validGateway]) => !!validating || !!validGateway)
        );
    }

    public remoteHighSpeedConnect(): void {
        this.isConnecting = true;
        this.remoteConnectionService.remoteHiSpeedConnectionResult.subscribe(connected => {
            this.isConnecting = false;
            if (connected) {
                this.genericMessageService.connectToHub();
                this.systemEventService.connectToHub();
            }
        });

        this.remoteConnectionService.createHiSpeedRemoteConnection(this.selectedGateway);
    }

    public onInputChanges($event: string): void {
        this.gatewayInputTooLong = $event.length >= this.inputMaxLength;
        this.validateSelectedGatewayInput($event);
    }

    public validateSelectedGatewayInput($event): void {
        this.gatewayChangedSubject.next($event);
    }
}
