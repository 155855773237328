import { SurveyDisplayLocation } from "./survey-display-location.enum";
import { SurveyQuestion } from "./survey-question.model";

export class SurveyModel {
    public id: any;
    public version: string;
    public startDate: Date;
    public endDate: Date;

    public creator: string;
    public creationDate: Date;
    public privacyPolicyLink: string;
    public url: string;

    public targetAudience: number[];
    public displayLocation: SurveyDisplayLocation;
    public questions: SurveyQuestion[];


    public title: { [key: string]: string };
    public description: { [key: string]: string };
    public thankYouMessage: { [key: string]: string };
}
