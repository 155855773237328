import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { SharedModule } from "src/app/shared/shared.module";
import { AvailableConnectionsComponent } from "./components/available-connections/available-connections.component";
import { RemoteConnectionComponent } from "./components/remote-connection/remote-connection.component";
import { SystemFileUploadComponent } from "./components/system-file-upload/system-file-upload.component";
import { StartPageComponent } from "./components/start-page.component";
import { StartPageRoutingModule } from "./start-page-routing.module";
import { BlackBaronConnectionComponent } from "./components/blackbaron-connection/blackbaron-connection.component";


@NgModule({
    declarations: [
        StartPageComponent,
        SystemFileUploadComponent,
        RemoteConnectionComponent,
        AvailableConnectionsComponent,
        BlackBaronConnectionComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        StartPageRoutingModule,
        MatButtonModule,
        MatInputModule,
        MatDividerModule,
        MatSelectModule,
        MatProgressSpinnerModule,
    ],
    providers: [],
    exports: [
        StartPageComponent
    ]
})
export class StartPageModule {}
