export class ConnectionStateIcons {
    static readonly CONNECTION_STATUS_NO_CONNECTION = 
    `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.73809 3.73809C4.21068 3.2655 4.85165 3 5.52 3H18.48C19.1483 3 19.7893 3.2655 20.2619 3.73809C20.7345 4.21068 21 4.85165 21 5.52V18.48C21 19.1483 20.7345 19.7893 20.2619 20.2619C19.7893 20.7345 19.1483 21 18.48 21H5.52C4.85165 21 4.21068 20.7345 3.73809 20.2619C3.2655 19.7893 3 19.1483 3 18.48V5.52C3 4.85165 3.2655 4.21068 3.73809 3.73809ZM5.52 4.8C5.32904 4.8 5.14591 4.87586 5.01088 5.01088C4.87586 5.14591 4.8 5.32904 4.8 5.52V18.48C4.8 18.671 4.87586 18.8541 5.01088 18.9891C5.14591 19.1241 5.32904 19.2 5.52 19.2H18.48C18.671 19.2 18.8541 19.1241 18.9891 18.9891C19.1241 18.8541 19.2 18.671 19.2 18.48V5.52C19.2 5.32904 19.1241 5.14591 18.9891 5.01088C18.8541 4.87586 18.671 4.8 18.48 4.8H5.52ZM16.86 12.9H7.14V11.1H16.86V12.9Z" fill="currentColor"/>
    </svg>
    `;

    static readonly CONNECTION_STATUS_FILE =
    `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 9.85714V17H9V7H13.2M16 9.85714L13.2 7M16 9.85714H13.2V7" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.73809 3.73809C4.21068 3.2655 4.85165 3 5.52 3H18.48C19.1483 3 19.7893 3.2655 20.2619 3.73809C20.7345 4.21068 21 4.85165 21 5.52V18.48C21 19.1483 20.7345 19.7893 20.2619 20.2619C19.7893 20.7345 19.1483 21 18.48 21H5.52C4.85165 21 4.21068 20.7345 3.73809 20.2619C3.2655 19.7893 3 19.1483 3 18.48V5.52C3 4.85165 3.2655 4.21068 3.73809 3.73809ZM5.52 4.8C5.32904 4.8 5.14591 4.87586 5.01088 5.01088C4.87586 5.14591 4.8 5.32904 4.8 5.52V18.48C4.8 18.671 4.87586 18.8541 5.01088 18.9891C5.14591 19.1241 5.32904 19.2 5.52 19.2H18.48C18.671 19.2 18.8541 19.1241 18.9891 18.9891C19.1241 18.8541 19.2 18.671 19.2 18.48V5.52C19.2 5.32904 19.1241 5.14591 18.9891 5.01088C18.8541 4.87586 18.671 4.8 18.48 4.8H5.52Z" fill="currentColor"/>
    </svg>
    `;

    static readonly CONNECTION_STATUS_REMOTE = 
    `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.73809 3.73809C4.21068 3.2655 4.85165 3 5.52 3H18.48C19.1483 3 19.7893 3.2655 20.2619 3.73809C20.7345 4.21068 21 4.85165 21 5.52V18.48C21 19.1483 20.7345 19.7893 20.2619 20.2619C19.7893 20.7345 19.1483 21 18.48 21H5.52C4.85165 21 4.21068 20.7345 3.73809 20.2619C3.2655 19.7893 3 19.1483 3 18.48V5.52C3 4.85165 3.2655 4.21068 3.73809 3.73809ZM5.52 4.8C5.32904 4.8 5.14591 4.87586 5.01088 5.01088C4.87586 5.14591 4.8 5.32904 4.8 5.52V18.48C4.8 18.671 4.87586 18.8541 5.01088 18.9891C5.14591 19.1241 5.32904 19.2 5.52 19.2H18.48C18.671 19.2 18.8541 19.1241 18.9891 18.9891C19.1241 18.8541 19.2 18.671 19.2 18.48V5.52C19.2 5.32904 19.1241 5.14591 18.9891 5.01088C18.8541 4.87586 18.671 4.8 18.48 4.8H5.52Z" fill="currentColor"/>
    <path d="M7.33334 8.49999V7.33333H7.39168C12.525 7.33333 16.6667 11.475 16.6667 16.6083V16.6667H15.5V16.6083C15.5 12.1167 11.8833 8.49999 7.33334 8.49999ZM7.33334 10.8333V9.66666C9.18986 9.66666 10.9703 10.4042 12.2831 11.7169C13.5958 13.0297 14.3333 14.8101 14.3333 16.6667H13.1667C13.1667 15.9006 13.0158 15.1421 12.7226 14.4343C12.4295 13.7266 11.9998 13.0835 11.4581 12.5419C10.9165 12.0002 10.2734 11.5705 9.56566 11.2774C8.85793 10.9842 8.09939 10.8333 7.33334 10.8333ZM7.33334 13.1667V12C8.57102 12 9.75801 12.4917 10.6332 13.3668C11.5083 14.242 12 15.429 12 16.6667H10.8333C10.8333 15.7384 10.4646 14.8482 9.80822 14.1918C9.15184 13.5354 8.2616 13.1667 7.33334 13.1667ZM7.33334 14.3333C7.95218 14.3333 8.54567 14.5792 8.98326 15.0167C9.42084 15.4543 9.66668 16.0478 9.66668 16.6667H7.33334V14.3333Z" fill="currentColor"/>
    </svg>
    `;

    static readonly CONNECTION_STATUS_CABLE = 
    `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.73809 3.73809C4.21068 3.2655 4.85165 3 5.52 3H18.48C19.1483 3 19.7893 3.2655 20.2619 3.73809C20.7345 4.21068 21 4.85165 21 5.52V18.48C21 19.1483 20.7345 19.7893 20.2619 20.2619C19.7893 20.7345 19.1483 21 18.48 21H5.52C4.85165 21 4.21068 20.7345 3.73809 20.2619C3.2655 19.7893 3 19.1483 3 18.48V5.52C3 4.85165 3.2655 4.21068 3.73809 3.73809ZM5.52 4.8C5.32904 4.8 5.14591 4.87586 5.01088 5.01088C4.87586 5.14591 4.8 5.32904 4.8 5.52V18.48C4.8 18.671 4.87586 18.8541 5.01088 18.9891C5.14591 19.1241 5.32904 19.2 5.52 19.2H18.48C18.671 19.2 18.8541 19.1241 18.9891 18.9891C19.1241 18.8541 19.2 18.671 19.2 18.48V5.52C19.2 5.32904 19.1241 5.14591 18.9891 5.01088C18.8541 4.87586 18.671 4.8 18.48 4.8H5.52Z" fill="currentColor"/>
    <path d="M16.6667 7.91667V7.33333C16.6667 7.0125 16.4042 6.75 16.0833 6.75H14.9167C14.5958 6.75 14.3333 7.0125 14.3333 7.33333V7.91667H13.75V10.25C13.75 10.5708 14.0125 10.8333 14.3333 10.8333H14.9167V14.9167C14.9167 15.5583 14.3917 16.0833 13.75 16.0833C13.1083 16.0833 12.5833 15.5583 12.5833 14.9167V9.08333C12.5833 7.79417 11.5392 6.75 10.25 6.75C8.96083 6.75 7.91667 7.79417 7.91667 9.08333V13.1667H7.33333C7.0125 13.1667 6.75 13.4292 6.75 13.75V16.0833H7.33333V16.6667C7.33333 16.9875 7.59583 17.25 7.91667 17.25H9.08333C9.40417 17.25 9.66667 16.9875 9.66667 16.6667V16.0833H10.25V13.75C10.25 13.4292 9.9875 13.1667 9.66667 13.1667H9.08333V9.08333C9.08333 8.44167 9.60833 7.91667 10.25 7.91667C10.8917 7.91667 11.4167 8.44167 11.4167 9.08333V14.9167C11.4167 16.2058 12.4608 17.25 13.75 17.25C15.0392 17.25 16.0833 16.2058 16.0833 14.9167V10.8333H16.6667C16.9875 10.8333 17.25 10.5708 17.25 10.25V7.91667H16.6667Z" fill="currentColor"/>
    </svg>    
    `;

    static readonly CONNECTION_STATUS_ERROR = 
    `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 3H5C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21H19C20.103 21 21 20.103 21 19V5C21 3.897 20.103 3 19 3ZM5 19V5H19L19.002 19H5Z" fill="currentColor"/>
    <rect x="11" y="15" width="2" height="2" fill="currentColor"/>
    <rect x="11" y="7" width="2" height="6" fill="currentColor"/>
    </svg>
    `;
}
