<div class="hic-information-mini-display"><mat-accordion [multi]="true">
        <mat-expansion-panel #collapsable>
            <mat-expansion-panel-header>Information</mat-expansion-panel-header>
            <ng-container *ngIf="showLoader">
                <hic-spinner></hic-spinner>
            </ng-container>
    
            <ng-container *ngIf="information">
                <div class="hic-info-table" *ngIf="parent.expanded"> 
                    <div class="information-row">
                        <div class="information-row-header" i18n="@@mini-information.clock">SPACE clock</div>
                        <div class="information-row-value">{{ information.spaceClock }}</div>
                    </div>
                    <div class="information-row">
                        <div class="information-row-header" i18n="@@mini-information.programversion">Program version</div>
                        <div class="information-row-value">{{ information.programVersion }}</div>
                    </div>
                    <div class="information-row">
                        <div class="information-row-header" i18n="@@mini-information.serialnumber">Serial number</div>
                        <div class="information-row-value">{{ information.spaceSerialNumber }}</div>
                    </div>
                    <div class="information-row">
                        <div class="information-row-header" i18n="@@mini-information.connectiontype">Connection type</div>
                        <div class="information-row-value">{{ information.connectionType }}</div>
                    </div>
                    <div class="information-row">
                        <div class="information-row-header" i18n="@@mini-information.connectedto">Connected to</div>
                        <div class="information-row-value">{{ information.connectedTo }}</div>
                    </div>
                    <div class="information-row">
                        <div class="information-row-header" i18n="@@mini-information.systemtype">System Type</div>
                        <div class="information-row-value">{{ systemTypes }}</div>
                    </div>
                    <div class="information-row">
                        <div class="information-row-header" i18n="@@mini-information.paytype">Pay Type</div>
                        <div class="information-row-value">{{ payTypes }}</div>
                    </div>
                    <div class="information-row">
                        <div class="information-row-header" i18n="@@mini-information.accesslevel">Access level</div>
                        <div class="information-row-value">{{ information.accessLevel }}</div>
                    </div>
                    <div class="information-row">
                        <div class="information-row-header" i18n="@@mini-information.craneserailnumber">Crane serial number
                        </div>
                        <div class="information-row-value">{{ information.craneSerialNumber }}</div>
                    </div>
                    <div class="information-row">
                        <div class="information-row-header" i18n="@@mini-information.cranetype">Crane type</div>
                        <div class="information-row-value">{{ information.craneType }}</div>
                    </div>
                    <div class="information-row">
                        <div class="information-row-header" i18n="@@mini-information.mainstabiliser">Main stabiliser</div>
                        <div class="information-row-value">{{ information.mainStabiliser }}</div>
                    </div>
                    <div *ngIf="information.hasAuxStabiliser" class="information-row">
                        <div class="information-row-header" i18n="@@mini-information.auxstabiliser">Auxiliary stabiliser
                        </div>
                        <div class="information-row-value">{{ information.auxStabiliser }}</div>
                    </div>
                    <div class="information-row" *ngIf="isConnectedSystemEvo">
                        <div class="information-row-header i18n">Capacity</div>
                        <!-- TODO! Create a inputfield for calculated test load, check figma design (HIAA-8805)-->
                        <div class="information-row-value">
                            <button class="show-diagram-button" mat-flat-button color="primary" i18n (click)="showDiagrams()">Show diagrams</button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </mat-expansion-panel>
    </mat-accordion>
    <hic-vsl-digram-view *ngIf="areDiagramsVisible" (closed)="hideDiagrams()"></hic-vsl-digram-view>
</div>